import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";

export interface IDesktopMenuController extends ViewController {
	readonly i18n: ILocalizationStore;
}

@injectable()
export class DesktopMenuController implements IDesktopMenuController {
	constructor(@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}
}
