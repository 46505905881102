import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IPoolPlayersStore} from "data/stores/pool_players/pool_players.store";
import type {IPoolPlayer} from "data/providers/api/tournament.provider";
import type {ITeamStore, ITrade} from "data/stores/team/team.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {IPlayerModalStore} from "data/stores/player_modal/player_modal.store";
import {eq, every, includes, some} from "lodash";
import {PlayerScoreType} from "data/enums";

interface IProps {
	playerId: number;
}

interface IPlayerScorePts {
	roundPoints: number | null | undefined;
	score: PlayerScoreType;
}

export interface IPlayerSlotRowController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get poolPlayer(): IPoolPlayer | undefined;
	get isCaptain(): boolean;
	get isTradeActive(): boolean;
	get trade(): ITrade;
	get isSelectedTournamentDisabled(): boolean;
	get isDisabledByCompleteRound(): boolean;
	get isButtonDisabled(): boolean;
	get isDisabledForSelectTeam(): boolean;
	get isDisabledPlayerByRound(): boolean;
	get isDisabledChangeTeam(): boolean;
	get isCaptainButtonDisabled(): boolean;
	get getTradeActiveClass(): string;
	get isEditTeam(): boolean;
	get hasTeam(): boolean;
	get hasPrevTeam(): boolean;
	get playerScoredRoundsPts(): IPlayerScorePts[];
	get totalScoredPts(): number | null | undefined;
	get fedexPlayerPoints(): number | null | undefined;
	get isPlaying(): boolean;
	get selectedRoundIndex(): number;

	onOpenPoolModal: () => void;
	onSetCaptain: () => void;
	onRemovePlayer: () => void;
	swapPlayers: () => void;
	openPlayerModal: () => void;
}

@injectable()
export class PlayerSlotRowController implements IPlayerSlotRowController {
	@observable private _playerId!: number;

	get poolPlayer() {
		return this._poolPlayersStore.getPoolPlayerById(this._playerId);
	}

	get isCaptain() {
		return this._playerId === this._teamStore.captainId;
	}

	get isTradeActive() {
		return this._teamStore.isTradeActive;
	}

	get trade() {
		return this._teamStore.trade;
	}

	get isSelectedTournamentDisabled() {
		return this._tournamentsStore.isSelectedTournamentDisabled;
	}

	get isDisabledByCompleteRound() {
		return this._tournamentsStore.isDisabledByCompleteRound;
	}

	get isDisabledChangeTeam() {
		return this._tournamentsStore.isDisabledChangeTeam;
	}

	get isButtonDisabled() {
		return some([this.isSelectedTournamentDisabled, this.isDisabledByCompleteRound]);
	}

	get isDisabledForSelectTeam() {
		return this._tournamentsStore.isDisabledForSelectTeam;
	}

	get isDisabledPlayerByRound() {
		const player = this.poolPlayer;

		if (!player) return true;

		// const {r1IsPlayed, r2IsPlayed, r3IsPlayed, r4IsPlayed} = player;
		const roundsPlayedData = player.roundStats.map((it) => it.isRoundPlayed);

		return roundsPlayedData[this._tournamentsStore.selectedRoundIndex];
	}

	get isCaptainButtonDisabled() {
		if (this.isDisabledByCompleteRound) {
			return true;
		} else {
			return this.hasPrevTeam;
		}
	}

	get getTradeActiveClass() {
		const outPlayerClass = "selected trade_out";
		const outPlayer = eq(this._playerId, this._teamStore.trade.out);
		const isBenchPlayer = includes(this._teamStore.bench, this._playerId);
		const isTradeFromSelected = includes(
			[...this._teamStore.lineup, ...this._teamStore.bench],
			this._teamStore.trade.out
		);
		const isTradeFromBench = includes(this._teamStore.bench, this._teamStore.trade.out);

		if (outPlayer) {
			return outPlayerClass;
		}

		if (every([this.isTradeActive, isBenchPlayer, isTradeFromSelected])) {
			return outPlayer ? outPlayerClass : "selected";
		}

		if (every([this.isTradeActive, !isBenchPlayer, isTradeFromBench])) {
			return outPlayer ? outPlayerClass : "selected";
		}

		return "";
	}

	get isEditTeam() {
		return this._teamStore.isEditTeam;
	}

	get hasTeam() {
		return this._teamStore.hasTeam;
	}

	get hasPrevTeam() {
		return this._teamStore.hasPrevTeam;
	}

	get selectedRoundIndex() {
		return this._tournamentsStore.selectedRoundIndex;
	}

	get playerScoredRoundsPts() {
		const tournamentRounds = this._tournamentsStore.selectedTournamentRounds;
		const scorePoolRounds = this.poolPlayer?.roundStats.map((it) => it.points);
		// const scorePoolRounds = [
		// 	this.poolPlayer?.r1Pts,
		// 	this.poolPlayer?.r2Pts,
		// 	this.poolPlayer?.r3Pts,
		// 	this.poolPlayer?.r4Pts,
		// ];

		return tournamentRounds.map((round, index) => {
			const scoreRound = this._teamStore.team?.roundPts.find(
				(it) => it?.roundId === round?.id
			);
			const isPlayerScore = Boolean(scoreRound?.ptsByPlayerId[this._playerId]);
			const currentRound = this.poolPlayer?.roundStats[index];
			const isRoundPlaying = Boolean(currentRound?.thru && currentRound.thru !== "F");

			if (isPlayerScore && isRoundPlaying) {
				return {
					roundPoints: scoreRound?.ptsByPlayerId[this._playerId],
					score: PlayerScoreType.Score,
				};
			}

			if (isPlayerScore) {
				return {
					roundPoints: scoreRound?.ptsByPlayerId[this._playerId],
					score: PlayerScoreType.Default,
				};
			}

			return {
				roundPoints: scorePoolRounds
					? scorePoolRounds[index]
					: scoreRound?.ptsByPlayerId[this._playerId],
				score: PlayerScoreType.UnScore,
			};
		});
	}

	get fedexPlayerPoints() {
		if (!this._teamStore.team.fedexPtsByPlayerId) return this.poolPlayer?.fedexPts;

		const isFedexScore = Boolean(this._teamStore.team.fedexPtsByPlayerId[this._playerId]);

		return isFedexScore
			? this._teamStore.team.fedexPtsByPlayerId[this._playerId]
			: this.poolPlayer?.fedexPts;
	}

	get totalScoredPts() {
		if (!this._teamStore.team.totalPtsByPlayerId) return this.poolPlayer?.pts;

		const isTotalScore = Boolean(this._teamStore.team.totalPtsByPlayerId[this._playerId]);

		return isTotalScore
			? this._teamStore.team.totalPtsByPlayerId[this._playerId]
			: this.poolPlayer?.pts;
	}

	get isPlaying() {
		return Boolean(this.poolPlayer?.thru && this.poolPlayer.thru !== "F");
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.PoolPlayersStore) public _poolPlayersStore: IPoolPlayersStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore,
		@inject(Bindings.PlayerModalStore) public _playerModalStore: IPlayerModalStore
	) {
		makeAutoObservable(this);
	}

	onSetCaptain = () => {
		this._teamStore.selectCaptain(this._playerId);
	};

	onRemovePlayer = () => {
		this._teamStore.removePlayer(this._playerId);
	};

	onOpenPoolModal = () => {
		this._poolPlayersStore.toggleHandler();
	};

	swapPlayers = () => {
		this._teamStore.swapPlayers(this._playerId);
	};

	openPlayerModal = () => {
		this._playerModalStore.setPlayerId(this._playerId);
		this._playerModalStore.openPlayerModal();
	};

	onChange({playerId}: IProps) {
		this._playerId = playerId;
	}

	init({playerId}: IProps) {
		this._playerId = playerId;
	}
}
