/**
 * Custom Errors handler
 */
export class ApiError extends Error {
	public static AUTH_REQUIRED = 401;
	public static INVALID_CREDENTIALS = 403;
	public static NOT_FOUND = 404;
	public static USER_NOT_REGISTERED = 409;

	// public static HAS_ERROR(response: NetworkError) {
	// 	return "message" in response || !isEmpty(response.errors);
	// }
	//
	// public static CHECK(response: NetworkError) {
	// 	if (ApiError.HAS_ERROR(response)) {
	// 		if ("errors" in response) {
	// 			const {errors, code} = response;
	// 			throw new ApiError(errors[0], code);
	// 		}
	//
	// 		throw new ApiError(response.message, 0);
	// 	}
	//
	// 	return response;
	// }

	public code?: number;
	public text?: string;

	constructor(text: string, code?: number) {
		super(text);
		this.name = "API Error";
		this.text = text;
		this.code = code;
	}
}
