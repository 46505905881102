import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {IUsesPlayer} from "data/providers/api/tournament.provider";

interface IProps {
	playerId: number;
}

export interface IPlayerUsesController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get usesPlayerData(): IUsesPlayer | undefined;
}

@injectable()
export class PlayerUsesController implements IPlayerUsesController {
	@observable private _playerId!: number;

	get usesPlayerData() {
		return this._tournamentsStore.usesPlayersList.find((it) => it.id === this._playerId);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	onChange({playerId}: IProps) {
		this._playerId = playerId;
	}

	init({playerId}: IProps) {
		this._playerId = playerId;
	}
}
