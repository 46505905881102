import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {ILeaderboardsStore, IPlayerRanking} from "data/stores/leaderboards/leaderboards.store";
import {orderBy, take} from "lodash";
import {TrackAnalytics} from "data/utils/analytic_service";

export interface IPopularPlayersController extends ViewController {
	readonly i18n: ILocalizationStore;

	get popularPlayers(): IPlayerRanking[];

	onTrackAction: () => void;
}

@injectable()
export class PopularPlayersController implements IPopularPlayersController {
	get popularPlayers() {
		const sortList = orderBy(this._leaderboardStore.playersRanking, ["owned"], ["asc"]);

		return take(sortList, 8);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaderboardsStore) public _leaderboardStore: ILeaderboardsStore
	) {
		makeAutoObservable(this);
	}

	onTrackAction = () => {
		const payload = {
			event: "Button Clicked",
			PageName: "home",
			SelectionName: "home",
			PageArea: "fantasy_leader",
		};

		TrackAnalytics.trackOnAction(payload);
	};
}
