import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {SyntheticEvent} from "react";
import {AxiosError, AxiosResponse} from "axios";
import {first, get} from "lodash";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {BonusType, ModalType, RequestState} from "data/enums";
import type {
	ICompareApiProvider,
	ITeamCompare,
	ITeamCompareByTournamentPayload,
	ITeamCompareByTournamentResponse,
	ITeamDataCompareLineup,
	ITeamDataCompareLineupBonuses,
} from "data/providers/api/compare.api.provider";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IPlayersStore} from "data/stores/players/players.store";

export interface ITeamPlayer extends ITeamDataCompareLineup {
	headshot?: string | null;
	name?: string;
}

export interface IBonus {
	bonus: BonusType;
	playerA?: ITeamDataCompareLineupBonuses;
	playerB?: ITeamDataCompareLineupBonuses;
}

export interface ILineUp {
	playerA?: ITeamPlayer;
	playerB?: ITeamPlayer;
	bonuses: IBonus[];
}

interface IProps {
	isOpen: boolean;
	tournamentId: number;
}

export interface ITournamentController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get rounds(): number[];
	get selectedRoundName(): number;
	get isLoading(): boolean;
	get team(): ITeamCompare | undefined;
	get teamsLineup(): ILineUp[];
	get bench(): ILineUp[];

	getValue: <T>(value: T) => T | string;
	changeRoundName: (event: SyntheticEvent, newValue: number) => void;
}

@injectable()
export class TournamentController implements ITournamentController {
	@observable private _tournamentId: number = 0;
	@observable private _selectedRoundName: number | null = null;
	@observable private _requestState = RequestState.IDLE;
	@observable private _teams: ITeamCompare[] = [];
	private _bonusList: BonusType[] = [
		BonusType.HoleDoubleEagle,
		BonusType.HoleEagle,
		BonusType.HoleBirdie,
		BonusType.HolePar,
		BonusType.HoleBogey,
		BonusType.HoleDoubleBogey,
		BonusType.HoleRoundBonus,
		BonusType.HoleFedex,
	];

	get rounds() {
		return this._teams.map(({roundName}) => roundName);
	}

	get selectedRoundName() {
		return this._selectedRoundName || 0;
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get team() {
		return this._teams.find(({roundName}) => this._selectedRoundName === roundName);
	}

	get teamsLineup() {
		const teamALineUp = get(this.team, "teamA.lineup", []);
		const teamBLineUp = get(this.team, "teamB.lineup", []);

		return this.getLineUpData(teamALineUp, teamBLineUp);
	}

	get bench() {
		const teamALineUp = get(this.team, "teamA.bench", []);
		const teamBLineUp = get(this.team, "teamB.bench", []);

		return this.getLineUpData(teamALineUp, teamBLineUp);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.CompareApiProvider) private _compareApiProvider: ICompareApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore
	) {
		makeAutoObservable(this);
	}

	private getLineUpData(
		teamALineUp: ITeamDataCompareLineup[],
		teamBLineUp: ITeamDataCompareLineup[]
	) {
		const length = Math.max(teamALineUp.length, teamBLineUp.length);
		return Array.from({length}).map((_, i) => {
			const playerTeamA = teamALineUp[i];
			const playerTeamB = teamBLineUp[i];

			const playerA = this.getTeamPlayerData(playerTeamA);
			const playerB = this.getTeamPlayerData(playerTeamB);
			const bonuses = this._bonusList.map((bonus) => {
				const playerABonus = playerA?.bonuses.find(({name}) => name === bonus);
				const playerBBonus = playerB?.bonuses.find(({name}) => name === bonus);

				return {
					bonus,
					playerA: playerABonus,
					playerB: playerBBonus,
				};
			});

			return {playerA, playerB, bonuses};
		});
	}

	private getTeamPlayerData(teamPlayer?: ITeamDataCompareLineup) {
		if (!teamPlayer) return undefined;

		const player = this._playersStore.getPlayerById(teamPlayer.playerId);

		if (!player) return undefined;

		return {
			...teamPlayer,
			headshot: player.headshot,
			name: `${player.firstName[0]}.${player.lastName}`,
		};
	}

	private onSuccess = (result: AxiosResponse<IApiResponse<ITeamCompareByTournamentResponse>>) => {
		this._requestState = RequestState.SUCCESS;
		this._teams = result.data.success.teams;
		this._selectedRoundName = first(this._teams)?.roundName || 0;
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	private get requestParams(): ITeamCompareByTournamentPayload | void {
		const userId = this.modalContent?.userId;
		const leagueId = this.modalContent?.leagueId;

		if (!userId || !leagueId) return;

		return {
			userId,
			leagueId,
			tournamentId: this._tournamentId,
		};
	}

	private fetchData() {
		if (!this.requestParams) return;

		this._requestState = RequestState.PENDING;
		this._compareApiProvider
			.compareByTournament(this.requestParams)
			.then(this.onSuccess)
			.catch(this.onError);
	}

	init(param: IProps) {
		this.onChange(param);
	}

	onChange(param: IProps) {
		this._tournamentId = param.tournamentId;

		if (param.isOpen) {
			this.fetchData();
		}
	}

	changeRoundName = (_: SyntheticEvent, newValue: number) => {
		this._selectedRoundName = newValue;
	};

	getValue = <T>(value: T) => {
		return value ?? "--";
	};
}
