import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {ITeamResponse} from "data/providers/api/team.api.provider";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {IRound} from "data/stores/rounds/rounds.store";

export interface ITeamScoredBarController extends ViewController {
	readonly i18n: ILocalizationStore;

	get teamScoredPoints(): Pick<ITeamResponse, "fedexPts" | "totalPts" | "roundPts">;
	get playingRound(): IRound | undefined;
	get selectedRoundIndex(): number;
	get isEditTeam(): boolean;

	onSelectRound: (index: number) => void;
}

@injectable()
export class TeamScoredBarController implements ITeamScoredBarController {
	get teamScoredPoints() {
		return this._teamStore.teamScoredPoints;
	}

	get playingRound() {
		return this._tournamentsStore.playingRound;
	}

	get selectedRoundIndex() {
		return this._tournamentsStore.selectedRoundIndex;
	}

	get isEditTeam() {
		return this._teamStore.isEditTeam;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	onSelectRound = (index: number) => {
		if (!this.isEditTeam) {
			this._tournamentsStore.setSelectedRoundIndex(index);
		}
	};
}
