import {GIGYA_KEY, GIGYA_SCREENSET_ID} from "data/constants";
import {GigyaScreenType} from "data/enums";

export class SSO {
	public login() {
		window.gigya?.sso.login({
			authFlow: "redirect",
			apiKey: GIGYA_KEY,
		});
	}

	public showScreenSet(type: GigyaScreenType): void {
		window.gigya?.accounts.showScreenSet({
			screenSet: GIGYA_SCREENSET_ID,
			startScreen: type,
		});
	}
}
