import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, SaveButtonState} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {includes, some} from "lodash";
import type {IUserStore} from "data/stores/user/user.store";

export interface ISaveBarController extends ViewController {
	readonly i18n: ILocalizationStore;

	get lineupSize(): number;
	get isCurrentLineupFull(): boolean;
	get isCurrentLineupHasPlayer(): boolean;
	get saveBtnCopy(): string;
	get isTeamChanged(): boolean;
	get saveButtonStateLoading(): boolean;
	get isDisabledCreateTeam(): boolean;
	get isDisabledByCompleteRound(): boolean;
	get isEditTeam(): boolean;
	get hasTeam(): boolean;
	get hasPrevTeam(): boolean;

	onClearTeam: () => void;
	onSaveTeam: () => void;
	onAutoFill: () => void;
}

@injectable()
export class SaveBarController implements ISaveBarController {
	get lineupSize() {
		return this._teamStore.lineupSize;
	}

	get isCurrentLineupFull() {
		return this._teamStore.isCurrentLineupFull;
	}

	get isCurrentLineupHasPlayer() {
		return this._teamStore.isCurrentLineupHasPlayer;
	}

	get saveBtnCopy() {
		return {
			[SaveButtonState.SAVE]: this.i18n.t("save_bar.button.save", "Save"),
			[SaveButtonState.SAVING]: this.i18n.t("save_bar.button.saving", "Saving"),
			[SaveButtonState.SAVED]: this.i18n.t("save_bar.button.saved", "Saved"),
			[SaveButtonState.UPDATE]: this.i18n.t("save_bar.button.update", "Update"),
			[SaveButtonState.UPDATING]: this.i18n.t("save_bar.button.updating", "Updating"),
			[SaveButtonState.UPDATED]: this.i18n.t("save_bar.button.updated", "Updated"),
		}[this._teamStore.saveButtonState];
	}

	get isTeamChanged() {
		return this._teamStore.isTeamChanged;
	}

	get saveButtonStateLoading() {
		return includes(
			[SaveButtonState.SAVING, SaveButtonState.UPDATING],
			this._teamStore.saveButtonState
		);
	}

	get isDisabledCreateTeam() {
		return some([
			this._tournamentsStore.isSelectedTournamentDisabled,
			this._tournamentsStore.isDisabledChangeTeam,
		]);
	}

	get isDisabledByCompleteRound() {
		return this._tournamentsStore.isDisabledByCompleteRound;
	}

	get isEditTeam() {
		return this._teamStore.isEditTeam;
	}

	get hasTeam() {
		return this._teamStore.hasTeam;
	}

	get hasPrevTeam() {
		return this._teamStore.hasPrevTeam;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	showTeamSavedModal = () => {
		this._modalsStore.showModal(ModalType.TEAM_SAVED, {
			message: this.i18n.t(
				"modal.team_saved.message",
				"You can make edits to your team up until the start of the first Round"
			),
		});
	};

	onClearTeam = () => {
		this._teamStore.clearLineup();
	};

	onSaveTeam = () => {
		if (!this._teamStore.captainId) {
			this._modalsStore.showModal(ModalType.SET_CAPTAIN, {
				title: this.i18n.t("modal.message.set_captain_title", "Attention"),
				message: this.i18n.t(
					"modal.message.set_captain_description",
					"Before save you team, please select captain"
				),
			});

			return;
		}

		if (this._userStore.isAuthorized) {
			void this._teamStore
				.saveTeam({
					tournamentId: this._tournamentsStore.selectedTournamentId,
					roundId: this._tournamentsStore.selectedRound.id,
					...this._teamStore.saveTeamPayload,
				})
				.then(this.showTeamSavedModal)
				.catch((error: AxiosError<IApiResponse>) => {
					this._modalsStore.showModal(ModalType.ERROR, {
						message: extractErrorMessage(error),
					});

					this._teamStore.setSaveState(SaveButtonState.SAVE);
				});
		} else {
			this._modalsStore.showModal(ModalType.AUTHORIZATION);
		}
	};

	onAutoFill = () => {
		void this._teamStore.autoFill();
	};
}
