import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export interface IRegistrationPayload {
	displayName: string;
	email: string;
	password: string;
}

export interface IUpdateUserPayload {
	displayName?: string;
	email?: string;
	isNotificationsEnabled?: boolean;
}

export interface IUsername {
	username: string;
}

export interface IAPILogin {
	uid: string;
	uidSignature: string;
	signatureTimestamp: string;
}

export interface IPgaAppLogin {
	pgaSession: string;
	pgaHash: string;
}

export interface IPgaAppRegister extends IPgaAppLogin {
	displayName: string;
	isNotificationsEnabled: boolean;
}

export interface IAPIRegister {
	displayName: string;
	isNotificationsEnabled: boolean;
	uid: string;
	uidSignature: string;
	signatureTimestamp: string;
}

export interface IGigyaResponse extends IAPILogin {
	firstName: string;
	lastName: string;
	birthDay: number;
	birthYear: number;
	birthMonth: number;
	country: string;
}

export type TUserResponse = IApiResponse<{user: IUser}>;

export interface IUserApiProvider {
	register: (params: IRegistrationPayload) => Promise<AxiosResponse<TUserResponse>>;
	update: (params: IUpdateUserPayload) => Promise<AxiosResponse<TUserResponse>>;
	user: () => Promise<AxiosResponse<TUserResponse>>;
	checkUsername: (params: IUsername) => Promise<AxiosResponse>;
	deactivateAccount: () => Promise<AxiosResponse>;
}

@injectable()
export class UserApiProvider implements IUserApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	checkUsername = (params: IUsername) => this._http.post<void>("user/check-username", params);

	deactivateAccount = () => this._http.post<void>("user/deactivate-account");

	register = (params: IRegistrationPayload) =>
		this._http.post<TUserResponse>("user/register", params);

	update = (params: IUpdateUserPayload) => this._http.post<TUserResponse>("user/update", params);

	user = () => this._http.get<TUserResponse>("user");
}
