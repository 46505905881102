import React from "react";
import {observer} from "mobx-react";
import {Button, Modal, Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import {Exist} from "views/components/exist/exist.component";
import type {IModalConfirmController} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {ButtonPrimary, ButtonSecondaryInvert} from "views/components/buttons/buttons.component";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	outline: none;
	max-width: 600px;
	width: 100%;
	background: #fff;
	padding: 20px;
	border-radius: 4px;
	text-align: center;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}

	button {
		max-width: 300px;
		min-width: 120px;
		margin-top: 14px;

		&.close_btn {
			max-width: unset;
			min-width: auto;
			margin-top: 0;
		}
	}

	p {
		color: #000;
		text-align: center;
		font-feature-settings:
			"clig" off,
			"liga" off;
		font-family: var(--fontFamilyBase);
		font-size: 18px;
		font-style: normal;
		font-weight: normal;
		line-height: 123%; /* 44.28px */
		letter-spacing: -0.7px;

		&.title {
			font-size: 36px;
			font-weight: 600;
			text-transform: capitalize;
		}
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	svg {
		path {
			fill: #000;
		}
	}
`;
const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #c20000;
	}
`;

export const ModalConfirm: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalConfirmController>(
		Bindings.ModalConfirmController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<Button className="close_btn" onClick={close}>
						<CloseIcon />
					</Button>
				</CloseBlock>
				<Icon>
					<ErrorIcon />
				</Icon>
				<Typography className="title">
					{i18n.t(modalContent?.title ?? "modal.confirm.title", "Confirm")}
				</Typography>
				<Exist when={modalContent?.message}>
					<Typography>{modalContent?.message}</Typography>
				</Exist>
				<Stack direction="row" gap={3}>
					<Exist when={modalContent?.callback}>
						<ButtonPrimary
							fullWidth
							variant="contained"
							onClick={modalContent?.callback}>
							{i18n.t("modal.confirm.confirm_button", "Confirm")}
						</ButtonPrimary>
					</Exist>
					<ButtonSecondaryInvert fullWidth variant="contained" onClick={close}>
						{i18n.t("modal.confirm.cancel_button", "Cancel")}
					</ButtonSecondaryInvert>
				</Stack>
			</ModalContent>
		</Modal>
	);
});
