import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {RequestState} from "data/enums";
import {isEqual} from "lodash";
import type {ChangeEvent, SyntheticEvent} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {PASSWORD_REQUIREMENTS} from "data/constants";
import {useNavigate} from "react-router-dom";
import type {IModalsStore} from "data/stores/modals/modals.store";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

interface IRegistrationFormElement extends HTMLFormElement {
	displayName: HTMLInputElement;
	email: HTMLInputElement;
	confirmEmail: HTMLInputElement;
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
	terms: HTMLInputElement;
}

export interface IRegistrationFormController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get isFormDisabled(): boolean;
	get error(): Record<string, string> | null;
	get displayName(): string;

	handleFormSubmit: (event: SyntheticEvent<IRegistrationFormElement>) => void;
	handleFormOnChange: (event: ChangeEvent<IRegistrationFormElement>) => void;
	handleInputDisplayNameValue: (event: ChangeEvent<HTMLInputElement>) => void;
	handleValidatePassword: (event: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class RegistrationFormController implements IRegistrationFormController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _displayName = "";

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get displayName(): string {
		return this._displayName;
	}

	get isFormDisabled(): boolean {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action public handleFormSubmit = (event: SyntheticEvent<IRegistrationFormElement>) => {
		event.preventDefault();

		const {displayName, email, confirmEmail, password, confirmPassword, terms} =
			event.currentTarget;

		const errorInvalidEmail = this.i18n.t(
			"registration.email.error",
			"Please provide a valid email address"
		);
		const errorInvalidConfirmEmail = this.i18n.t(
			"registration.confirm_email.error",
			"Confirm email address is invalid"
		);
		const errorInvalidUsername = this.i18n.t(
			"registration.display_name.error",
			"Please provide your display name"
		);
		const errorInvalidPassword = this.i18n.t(
			"registration.password.error",
			PASSWORD_REQUIREMENTS
		);
		const errorInvalidTerms = this.i18n.t(
			"registration.terms.error",
			"Please accept Terms & Conditions"
		);
		const errorEmailsMismatch = this.i18n.t(
			"registration.email.mismatch_error",
			"Emails do not match"
		);
		const errorPasswordsMismatch = this.i18n.t(
			"registration.password.mismatch_error",
			"Passwords do not match"
		);

		const validateList = [
			{field: email, error: errorInvalidEmail, place: "email"},
			{field: confirmEmail, error: errorInvalidConfirmEmail, place: "confirmEmail"},
			{field: displayName, error: errorInvalidUsername, place: "displayName"},
			{field: password, error: errorInvalidPassword, place: "password"},
			{field: terms, error: errorInvalidTerms, place: "terms"},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		if (email.value !== confirmEmail.value) {
			return this.reportError(errorEmailsMismatch, "confirmEmail");
		}

		if (password.value !== confirmPassword.value) {
			return this.reportError(errorPasswordsMismatch, "confirmPassword");
		}

		const payload = {
			displayName: displayName.value,
			email: email.value,
			password: password.value,
		};

		this._requestState = RequestState.PENDING;
		this._userStore
			.register(payload)
			.then(() => {
				this._modalsStore.hideModal();
			})
			.catch(this.onError);
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	@action handleFormOnChange = (event: ChangeEvent<IRegistrationFormElement>) => {
		if (event.target.name !== "password") {
			this._errorMsg = null;
			this._errorPlace = "";
		}
	};

	@action handleInputDisplayNameValue = (event: ChangeEvent<HTMLInputElement>) => {
		this._displayName = event.target.value.replace("@", "");
	};

	@action handleValidatePassword = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checkValidity()) {
			this._errorMsg = null;
		} else {
			const errorInvalidPassword = this.i18n.t(
				"registration.password.error",
				PASSWORD_REQUIREMENTS
			);
			this.reportError(errorInvalidPassword, "password");
		}
	};
}
