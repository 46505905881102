import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import {GigyaScreenType, ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {SSO} from "data/utils/gigya_sso";

export interface ILandingController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isAuthorized(): boolean;
	openAuthorizationModal(): void;
	onGigyaLogin: () => void;
	onGigyaRegister: () => void;
}

@injectable()
export class LandingController implements ILandingController {
	_sso = new SSO();
	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	openAuthorizationModal = () => {
		this._modalsStore.showModal(ModalType.AUTHORIZATION);
	};

	onGigyaLogin = () => {
		this._sso.showScreenSet(GigyaScreenType.Login);
	};
	onGigyaRegister = () => {
		this._sso.showScreenSet(GigyaScreenType.Register);
	};
}
