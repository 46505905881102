import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {Box, FormControl, Modal, Stack} from "@mui/material";
import {Checkbox, ErrorText, Input, Label} from "views/components/form";
import {Exist} from "views/components/exist/exist.component";
import {ButtonPrimary} from "views/components/buttons/buttons.component";
import styled from "@emotion/styled";
import {some} from "lodash";
import {ReactComponent as PGALogo} from "assets/img/register_pga_logo.svg";
import type {IModalSecondaryRegistrationController} from "views/components/modals/modal_secondary_register/modal_secondary_register.controller";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
	outline: none;
	max-width: 600px;
	width: 100%;
	background: #fff;
	padding: 20px 20px 24px 20px;
	border-radius: 4px;
	text-align: center;
	color: #070a0d;
	font-family: var(--fontFamilyBase);

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}

	button {
		max-width: 300px;
	}
`;

const Form = styled("form")`
	width: 100%;
	margin: 20px auto 20px;

	.MuiFilledInput-root {
		//background: #2c2c2e;

		&:focus {
			&:before {
				border-bottom: 1px solid #aeaeb2;
			}
		}

		&:before {
			border-bottom: 1px solid #aeaeb2;
		}
	}

	label {
		display: flex;
		align-items: center;

		a {
			font-weight: bold;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	input {
		color: #090b0f !important;
	}
`;

const ButtonRow = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 12px;
	width: 100%;
	padding: 24px 0 6px;
`;
const ImageContainer = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 0;
	margin: 24px 0 40px;
`;
const Title = styled("h4")`
	color: #000;
	text-align: center;
	font-feature-settings:
		"clig" off,
		"liga" off;
	font-family: var(--fontFamilyBase);
	font-size: 36px;
	font-style: normal;
	font-weight: 600;
	line-height: 123%; /* 44.28px */
	letter-spacing: -0.7px;
	text-transform: capitalize;
	margin-bottom: 20px;
`;
const Description = styled("p")`
	color: #000;
	text-align: center;
	font-feature-settings:
		"clig" off,
		"liga" off;
	font-family: var(--fontFamilyBase);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 24px */
	margin-bottom: 20px;
`;

export const ModalSecondaryRegistration: React.FC = observer(() => {
	const {
		i18n,
		isOpen,
		error,
		isFormDisabled,
		isFormDisabledByData,
		handleInputUserNameValue,
		handleCheckboxChange,
		handleFormSubmit,
		handleFormOnChange,
	} = useViewController<IModalSecondaryRegistrationController>(
		Bindings.ModalSecondaryRegistrationController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<Box sx={{maxWidth: 400, margin: "0 auto"}}>
					<ImageContainer>
						<PGALogo />
					</ImageContainer>
					<Title>{i18n.t("modal.registration.title", "Welcome to PGA Fantasy")}</Title>
					<Description>
						{i18n.t(
							"modal.registration.description",
							"To get started give yourself a display name and accept our game terms to be eligible to win Prizes"
						)}
					</Description>
					<Form onSubmit={handleFormSubmit} onChange={handleFormOnChange}>
						<Stack direction="column" gap={2.5}>
							<FormControl fullWidth>
								<Input
									defaultValue={""}
									onInput={handleInputUserNameValue}
									variant={"filled"}
									label={i18n.t("modal.registration.user_name", "User name")}
									name="username"
									type="text"
									placeholder={i18n.t(
										"modal.registration.user_name",
										"User name"
									)}
									inputProps={{maxLength: 25}}
									required={true}
									error={Boolean(error?.username)}
									helperText={error?.username}
								/>
							</FormControl>
							<Stack direction="row" gap={2}>
								<Checkbox
									disabled={isFormDisabled}
									name="terms"
									id="terms"
									onChange={handleCheckboxChange}
									required={true}
								/>
								<Label htmlFor="terms">
									{i18n.t(
										"modal.registration.terms_part1",
										"I have read and accepted the"
									)}
									&nbsp;
									<a href="/terms">
										{i18n.t(
											"modal.registration.terms_part2",
											"Terms & Conditions"
										)}
									</a>
									{"*"}
								</Label>
							</Stack>
							<Exist when={error?.terms}>
								<ErrorText>{error?.terms}</ErrorText>
							</Exist>
							<Stack direction="row" gap={2}>
								<Checkbox
									disabled={isFormDisabled}
									name="isNotificationsEnabled"
									id="isNotificationsEnabled"
									onChange={handleCheckboxChange}
								/>
								<Label htmlFor="isNotificationsEnabled">
									{i18n.t(
										"modal.registration.notifications",
										"I wish to receive email and marketing."
									)}
									{"*"}
								</Label>
							</Stack>
						</Stack>
						<ButtonRow>
							<ButtonPrimary
								fullWidth
								variant="contained"
								type="submit"
								size="large"
								disabled={some([isFormDisabled, isFormDisabledByData])}>
								{i18n.t("modal.confirm.confirm", "Get Started")}
							</ButtonPrimary>
						</ButtonRow>
					</Form>
				</Box>
			</ModalContent>
		</Modal>
	);
});
