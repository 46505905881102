/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";
import {KeyboardArrowDownRounded} from "@mui/icons-material";
import {selectClasses} from "@mui/material";

export const componentsThemeOptions: ThemeOptions = {
	components: {
		MuiSelect: {
			defaultProps: {
				size: "small",
				variant: "outlined",
				fullWidth: true,
				IconComponent: KeyboardArrowDownRounded,
				inputProps: {
					MenuProps: {
						disablePortal: true,
						MuiPaperProps: {
							sx: {
								borderRadius: 0,
							},
						},
						MenuListProps: {
							sx: {
								paddingTop: 0,
								paddingBottom: 0,
							},
						},
					},
				},
			},
			styleOverrides: {
				root: {
					[`& .${selectClasses.select}`]: {
						padding: "12.5px 20px",
						fontSize: "14px",
						fontWeight: "600",
						lineHeight: "155%",
					},
					[`& .MuiOutlinedInput-notchedOutline`]: {
						borderColor: "#D6D4D2",
						borderWidth: "1px",
						borderRadius: "72px",
					},
					[`&&.Mui-focused .MuiOutlinedInput-notchedOutline`]: {
						borderColor: "#000",
						borderWidth: "1px",
					},
					[`& .${selectClasses.icon}`]: {
						right: "18px",
					},
					[`& .MuiPopover-paper`]: {
						borderRadius: 0,
					},
					[`& .MuiMenuItem-root`]: {
						fontFamily: "Lato, sans-serif",
						fontSize: "12px",
						fontWeight: "400",
						lineHeight: "135%",
						borderBottom: "1px solid #757575",
						transition: "background-color .1s",
						minHeight: "32px",

						"@media (min-width: 768px)": {
							fontSize: "14px",
							padding: "8px 16px",
						},
					},
					[`& .MuiMenuItem-root.Mui-selected, & .MuiMenuItem-root:hover, & .MuiMenuItem-root.Mui-selected:hover`]:
						{
							backgroundColor: "#000",
							color: "#fff",
						},
				},
			},
		},
	},
};
