import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ChangeEvent} from "react";
import {chain, includes, size} from "lodash";
import {TournamentStatus} from "data/enums";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ISegmentsStore} from "data/stores/segments/segments.store";

interface IProps {
	segmentId: number;
}
export interface ISegmentsAccordionController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get segmentId(): number;
	get expanded(): boolean;
	get isChecked(): boolean;

	setExpanded: () => void;
	handlerChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class SegmentsAccordionController implements ISegmentsAccordionController {
	@observable private _segmentId: number = 0;
	@observable private _expanded: boolean = false;

	get segmentId() {
		return this._segmentId;
	}
	get expanded() {
		return this._expanded;
	}

	get possibleTournamentsIds() {
		return chain(this._tournamentsStore.list)
			.filter(
				(it) =>
					it.seasonSegmentId === this._segmentId &&
					it.status === TournamentStatus.Scheduled
			)
			.map((it) => it.id)
			.value();
	}

	get selectedTournamentsIds() {
		return this.possibleTournamentsIds.filter((it) =>
			includes(this._leaguesStore.tournamentsIds, it)
		);
	}

	get isChecked() {
		return size(this.possibleTournamentsIds) === size(this.selectedTournamentsIds);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.SegmentsStore) private _segmentsStore: ISegmentsStore
	) {
		makeAutoObservable(this);
	}

	setExpanded = () => {
		this._expanded = !this._expanded;
	};

	handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
		const {checked, value} = e.target;

		const segmentTournamentsIds = chain(this._tournamentsStore.list)
			.filter(
				(it) =>
					it.seasonSegmentId === Number(value) && it.status === TournamentStatus.Scheduled
			)
			.map((it) => it.id)
			.value();

		if (checked) {
			this._leaguesStore.addTournamentArrToList(segmentTournamentsIds);
		} else {
			this._leaguesStore.removeTournamentArrFromList(segmentTournamentsIds);
		}
	};

	onChange({segmentId}: IProps) {
		this._segmentId = segmentId;
	}

	init({segmentId}: IProps) {
		this._segmentId = segmentId;
	}
}
