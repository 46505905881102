import React, {Fragment} from "react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {NavLink, useLocation} from "react-router-dom";
import {Exist} from "views/components/exist/exist.component";
import {Button, Collapse} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {IMenuItemWithSubLinks} from "data/types/navigation";
import styled from "@emotion/styled";
import {IMobileLinkController} from "views/components/mobile_links/mobile_link.controller";
import {observer} from "mobx-react";

export const MobileItem = styled.li`
	list-style: none;
	width: 100%;
	display: flex;
	align-items: center;
	text-align: left;

	&:last-child {
		border-bottom: none;
	}

	a,
	button {
		border-radius: 0;
		height: 100%;
		padding: 12px 16px;
		width: 100%;
		text-align: left;
		color: #000;
		font-size: 14px;
		font-style: normal;
		justify-content: flex-start;
		font-weight: 700;
		min-height: 44px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #000000;

		&.active {
			background: #000;
			color: #fff;
		}
	}

	li a,
	li button {
		padding-left: 32px;
	}

	li li a,
	li li button {
		padding-left: 64px;
	}

	.selected,
	.selected:hover {
		border-bottom: 1px solid #000000;
		background: #000;
		color: #fff;
	}

	&.highlight,
	.highlight {
		background: #000;
		color: #fff;

		a,
		button {
			background: #000;
			color: #fff;
		}
	}
`;

interface IMobileLink {
	link: IMenuItemWithSubLinks;
	closeHandler: () => void;
}

const MobileLink: React.FC<IMobileLink> = observer(({link, closeHandler}) => {
	const {i18n, isSelected, topLevelHighlight, open, toggleClick} =
		useViewController<IMobileLinkController>(Bindings.MobileLinkController, {
			link,
			pathname: useLocation().pathname,
		});

	const selectedClassName = isSelected ? "selected" : "";

	return (
		<Fragment key={link.key}>
			<MobileItem className={topLevelHighlight}>
				<Exist when={link.submenu}>
					<Button
						className={`justify-between ${selectedClassName} ${topLevelHighlight}`}
						onClick={toggleClick}>
						{i18n.t(link.key, link.value)}
						{open ? <ExpandLess /> : <ExpandMore />}
					</Button>
				</Exist>
				<Exist when={!link.submenu}>
					<Exist when={link.external}>
						<a href={link.to}>{i18n.t(link.key, link.value)}</a>
					</Exist>
					<Exist when={!link.external}>
						<NavLink to={link.to} end onClick={closeHandler}>
							{i18n.t(link.key, link.value)}
						</NavLink>
					</Exist>
				</Exist>
			</MobileItem>
			<Exist when={link.submenu && open}>
				<MobileItem>
					<Collapse className={`w-full`} in={open} timeout="auto" unmountOnExit>
						<MobileLinks links={link.submenu!} closeHandler={closeHandler} />
					</Collapse>
				</MobileItem>
			</Exist>
		</Fragment>
	);
});

interface IMobileLinks {
	links: IMenuItemWithSubLinks[];
	closeHandler: () => void;
}

export const MobileLinks: React.FC<IMobileLinks> = ({links, closeHandler}) => {
	return (
		<nav>
			{links.map((link) => {
				return <MobileLink key={link.key} link={link} closeHandler={closeHandler} />;
			})}
		</nav>
	);
};
