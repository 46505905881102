/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 */
import {PaletteColorOptions, ThemeOptions, Theme as MUITheme} from "@mui/material/styles";
import {ColorPartial} from "@mui/material/styles/createPalette";

declare module "@mui/material/styles/createPalette" {
	interface CustomTypeText {
		light?: string;
		medium?: string;
		dark?: string;
		custom?: string;
	}

	interface TypeText extends CustomTypeText {}
}

declare module "@mui/material/styles" {
	interface CustomPalette {
		apple: PaletteColorOptions;
	}

	interface Palette extends CustomPalette {}

	interface PaletteOptions extends CustomPalette {}

	interface PaletteColor extends ColorPartial {}
}

declare module "@emotion/react" {
	export interface Theme extends MUITheme {
		palette: NonNullable<MUITheme["palette"]>;
	}
}

export const paletteTheme: ThemeOptions = {
	// palette: {
	// 	primary: {
	// 		main: "#151B34",
	// 		light: "#95ECFD",
	// 		dark: "#0000DC",
	// 	},
	// 	secondary: {
	// 		main: "#373B57",
	// 		light: "#636366",
	// 	},
	// 	apple: {
	// 		main: "#FFF",
	// 	},
	// 	error: {
	// 		main: "#ff0000",
	// 	},
	// 	warning: {
	// 		main: "#fff100",
	// 	},
	// 	info: {
	// 		main: "#95ECFD",
	// 	},
	// 	success: {
	// 		main: "#60ff00",
	// 	},
	// 	text: {
	// 		light: "#FFF",
	// 		medium: "#6D6D6D",
	// 		dark: "#151B34",
	// 		custom: "#25347B",
	// 	},
	// },
};
