import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalPayload, IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import {useNavigate} from "react-router-dom";
import {find, sortBy} from "lodash";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {ITournament, ITournamentsStore} from "data/stores/tournaments/tournaments.store";

interface IProps {
	code?: string;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IModalLeagueJoinController extends ViewController<IProps> {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get modalContent(): IModalPayload | null;
	get leagueByCode(): ILeague | null | undefined;
	get startingTournament(): ITournament | undefined;

	close: () => void;
	onJoin: () => void;
}

@injectable()
export class ModalLeagueJoinController implements IModalLeagueJoinController {
	@observable private _code: string | undefined = "";
	@observable _navigate!: ReturnType<typeof useNavigate>;

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.LEAGUE_JOIN;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get leagueByCode() {
		return this._leaguesStore.leagueByCode;
	}

	get startingTournament() {
		const startTournamentId = sortBy(this.leagueByCode?.tournamentIds, "tournamentIds")[0];

		return this._tournamentsStore.getTournamentById(startTournamentId);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	close = () => {
		localStorage.removeItem("join_code");
		this._modalsStore.hideModal();
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	private redirectToMyLeagueByCode = ({
		code,
		replace = false,
	}: {
		code: string;
		replace?: boolean;
	}) => {
		const {leagues} = this._leaguesStore.myLeagues;
		const id = find(leagues, {code})?.id;
		if (id) this._navigate(`/league/${id}/`, {replace});
	};

	onJoin = () => {
		if (this._code) {
			this._leaguesStore
				.joinToLeague({code: this._code})
				.then(() =>
					this.redirectToMyLeagueByCode({
						code: this._code ? this._code : "",
						replace: true,
					})
				)
				.catch(this.onError);
		}

		this.close();
	};

	init({code, navigate}: IProps) {
		this._code = code;
		this._navigate = navigate;
	}
}
