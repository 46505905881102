import emptyPlayer from "assets/img/empty_player.png";
import {includes, isNumber} from "lodash";
import {RoundStatusFeed} from "data/enums";

export const numberOrEmpty = (value: number | null | undefined) => value || "";

export const numberOrSlash = (value: number | string | null | undefined, defValue = "-") => {
	if (isNumber(value)) {
		return value;
	}

	return value || defValue;
};
export const numberOrZero = (value: number | null | undefined) => value || 0;

export const getPlayerHeadshot = (value: string | null) => {
	if (!value || includes(value, "default")) {
		return emptyPlayer;
	}

	return value;
};

export const percentValue = (value: number | null | undefined) => {
	return value ? `${value}%` : "-";
};

// export const onScrollToElement = (id: string) => {
// 	const el = document.getElementById(id);
//
// 	el && el.scrollIntoView({
// 		behavior: "smooth",
// 		block: "start",
// 	});
// }

export const getScheduledRoundStatuses = () => {
	return [RoundStatusFeed.GROUPINGS_OFFICIAL, RoundStatusFeed.NOT_STARTED];
};

export const getPlayingRoundStatuses = () => {
	return [RoundStatusFeed.IN_PROGRESS, RoundStatusFeed.SUSPENDED];
};

export const getCompletedRoundStatuses = () => {
	return [RoundStatusFeed.OFFICIAL, RoundStatusFeed.PLAY_COMPLETE];
};
