import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IPlayerModalController} from "views/components/player_modal/player_modal.controller";
import {Box, Button, Modal} from "@mui/material";
import {ModalContent} from "views/components/modals/modalStyles";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import {Preloader} from "views/components/preloader";
import {getPlayerHeadshot, numberOrSlash} from "data/utils/helper";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {PlayerModalTabValue, PlayerStatus} from "data/enums";
import {ScorecardTab} from "views/components/tabs/scorecard_tab.component";
import {PerformanceTab} from "views/components/tabs/performance_tab.component";
import {ReactComponent as FedExIcon} from "assets/img/FedExCup_logo.svg";
import {Exist} from "views/components/exist/exist.component";
import dayjs from "dayjs";

const CloseButton = styled(Button)`
	position: absolute;
	top: 12px;
	right: 12px;
	border-radius: 50%;
	border: 1px solid #d6d4d2;
	padding: 8px;

	svg {
		path {
			fill: #000;
		}
	}
`;
const MainBox = styled(Box)`
	min-height: 560px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
const ModalHeader = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 4px;

	> div {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
`;
const ModalBody = styled(Box)`
	padding: 14px 0;

	.MuiTabPanel-root {
		padding: 14px 2px 6px;
		min-height: 240px;
	}

	.Mui-selected {
		color: #000 !important;
		font-weight: bold;
	}

	.MuiTabs-indicator {
		background-color: #000;
	}
`;
const ImageBox = styled(Box)`
	width: 60px;
	min-width: 60px;
	height: 60px;
	position: relative;
`;
const PlayerImage = styled(Box)`
	width: 60px;
	min-width: 60px;
	height: 60px;
	border-radius: 50%;
	overflow: hidden;
	background: #d6d4d2;
	cursor: pointer;

	img {
		display: block;
		width: 100%;
	}
`;
const StatusBox = styled(Box)`
	position: absolute;
	bottom: -2px;
	right: -4px;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	background: #d6000a;
	font-size: 11px;
	text-transform: uppercase;
	font-feature-settings:
		"clig" off,
		"liga" off;
	font-family: var(--fontFamilyBase);
	font-style: normal;
	font-weight: 600;
	line-height: 150%; /* 24px */
`;
const Name = styled(Box)`
	color: #000;
	font-feature-settings:
		"clig" off,
		"liga" off;
	font-family: var(--fontFamilyBase);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 135%; /* 32.4px */
	letter-spacing: -0.5px;
	text-transform: capitalize;
`;
const PointsBox = styled(Box)`
	display: flex;
	justify-content: flex-start;
	gap: 10px;

	@media (max-width: 560px) {
		display: grid !important;
		grid-template-columns: repeat(3, 1fr) !important;
	}
`;
const PointCell = styled(Box)`
	display: flex;
	padding: 4px 8px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2px;
	width: 100%;
	max-width: 104px;

	border-radius: 4px;
	border: 1px solid #d6d4d2;

	&.border {
		border: 1px solid #000;
	}
`;
const CellTitle = styled(Box)`
	display: flex;
	justify-content: center;
	color: #000;
	text-align: center;
	font-family: var(--fontFamilyBase);
	font-size: 8px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;
const CellValue = styled(Box)`
	display: flex;
	justify-content: center;
	color: #000;
	text-align: center;
	font-feature-settings:
		"clig" off,
		"liga" off;
	font-family: var(--fontFamilyBase);
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 135%; /* 16.2px */
	letter-spacing: 0.2px;
`;
const SponsorRow = styled(Box)`
	display: flex;
	justify-content: flex-end;
	gap: 6px;
	margin-top: 14px;

	> span {
		margin: 0 5px;
		color: rgba(0, 0, 0, 0.2);
		font-size: 22px;
	}
`;
const RowCell = styled(Box)`
	color: #000;
	text-align: right;
	font-family: var(--fontFamilyBase);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 155%; /* 21.7px */

	span {
		font-weight: bold;
	}
`;

export const PlayerModal: React.FC = observer(() => {
	const {
		i18n,
		isOpen,
		isLoading,
		currentPlayer,
		poolPlayer,
		isActivePlayer,
		playerData,
		selectedTab,
		currentRoundIndex,
		close,
		onTabChange,
	} = useViewController<IPlayerModalController>(Bindings.PlayerModalController);

	if (!currentPlayer) {
		return null;
	}

	return (
		<Modal open={isOpen}>
			<ModalContent className="player_modal">
				<CloseButton sx={{minWidth: 0}} onClick={close}>
					<CloseIcon />
				</CloseButton>
				{isLoading ? (
					<Preloader />
				) : (
					<MainBox>
						<ModalHeader>
							<ImageBox>
								<PlayerImage>
									<img
										src={getPlayerHeadshot(currentPlayer.headshot)}
										alt="player icon"
									/>
								</PlayerImage>
								<Exist when={!isActivePlayer}>
									{poolPlayer && (
										<StatusBox>
											{
												{
													[PlayerStatus.Active]: "AC",
													[PlayerStatus.Inactive]: "IA",
													[PlayerStatus.Disqualified]: "DQ",
													[PlayerStatus.Cut]: "MC",
													[PlayerStatus.Withdrawn]: "WD",
													[PlayerStatus.MadeCutDidntFinish]: "MDF",
												}[poolPlayer.status]
											}
										</StatusBox>
									)}
								</Exist>
							</ImageBox>
							<Box>
								<Name>
									{currentPlayer.firstName} {currentPlayer.lastName}
								</Name>
							</Box>
							<PointsBox>
								<PointCell>
									<CellTitle>
										{i18n.t("player.modal.round_score", "Round Score")}
									</CellTitle>
									<CellValue>
										{numberOrSlash(playerData?.scorecard.roundScore)}
									</CellValue>
								</PointCell>
								<PointCell>
									<CellTitle>
										{i18n.t("player.modal.round_bonus", "Round Bonus")}
									</CellTitle>
									<CellValue>
										{numberOrSlash(playerData?.scorecard.endRoundBonusPts)}
									</CellValue>
								</PointCell>
								<PointCell>
									<CellTitle>
										{i18n.t("player.modal.round_total", "Total")}
									</CellTitle>
									<CellValue>
										{numberOrSlash(playerData?.scorecard.total)}
									</CellValue>
								</PointCell>
								<PointCell>
									<CellTitle>
										{i18n.t("player.modal.round_thru", "THRU")}
									</CellTitle>
									<CellValue>
										{playerData?.scorecard.thru
											? numberOrSlash(playerData?.scorecard.thru)
											: playerData?.scorecard.teeTime
												? dayjs(playerData.scorecard.teeTime).format(
														"h:mm A"
													)
												: "-"}
									</CellValue>
								</PointCell>
								<PointCell>
									<CellTitle>
										{i18n.t("player.modal.round_fedex", "FedEx Cup")}
									</CellTitle>
									<CellValue>
										{numberOrSlash(playerData?.scorecard.fedexPts)}
									</CellValue>
								</PointCell>
								<PointCell className="border">
									<CellTitle>
										{i18n.t("player.modal.round_total_pts", "Total Pts")}
									</CellTitle>
									<CellValue>
										{numberOrSlash(playerData?.scorecard.totalPts)}
									</CellValue>
								</PointCell>
							</PointsBox>
						</ModalHeader>

						<ModalBody>
							<TabContext value={selectedTab}>
								<Box sx={{borderBottom: 1, borderColor: "divider"}}>
									<TabList onChange={onTabChange} aria-label="player_stats">
										<Tab
											label={i18n.t(
												"player.modal.tabs.scorecard",
												"SCORECARD"
											)}
											value={PlayerModalTabValue.SCORECARD}
										/>
										<Tab
											label={i18n.t(
												"player.modal.tabs.performance",
												"PERFORMANCE"
											)}
											value={PlayerModalTabValue.PERFORMANCE}
										/>
									</TabList>
								</Box>
								<TabPanel value={PlayerModalTabValue.SCORECARD}>
									<ScorecardTab
										data={playerData?.scorecard}
										currentRoundIndex={currentRoundIndex}
									/>

									<SponsorRow>
										<FedExIcon />
										<RowCell>
											{i18n.t("player.modal.bonus_copy", "Bonus")}
											{" : "}
											<span>
												{numberOrSlash(playerData?.scorecard.fedexPts)}
											</span>
										</RowCell>
										<span>|</span>
										<RowCell>
											{i18n.t("player.modal.round_bonus_copy", "Rd Bonus")}
											{" : "}
											<span>
												{numberOrSlash(
													playerData?.scorecard.endRoundBonusPts
												)}
											</span>
										</RowCell>
									</SponsorRow>
								</TabPanel>
								<TabPanel value={PlayerModalTabValue.PERFORMANCE}>
									<PerformanceTab data={playerData?.performance} />
								</TabPanel>
							</TabContext>
						</ModalBody>
					</MainBox>
				)}
			</ModalContent>
		</Modal>
	);
});
