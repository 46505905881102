import Slider, {Settings} from "react-slick";
import {Box, styled} from "@mui/material";
import {IHole, IScorecard} from "data/stores/player_modal/player_modal.store";
import React, {useRef} from "react";
import {useViewController} from "data/services/locator";
import type {ILocalizationController} from "views/controllers/localization/localization.controller";
import {Bindings} from "data/constants/bindings";
import arrowIcon from "assets/img/icons/arrow-icon.svg";
import {eq, gt, lt, take} from "lodash";
import {numberOrSlash} from "data/utils/helper";
import {Exist} from "views/components/exist/exist.component";

const TabBox = styled(Box)`
	.slick-slider {
		.slick-list {
			margin-top: 40px;
		}

		.slick-dots {
			top: -40px;

			counter-reset: section;
			display: flex !important;
			justify-content: center;
			gap: 10px;

			li {
				width: 24px;
				height: 24px;
				border: 1px solid #000;
				border-radius: 12px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 16px;
				counter-increment: section;
				content: counters(section);
				overflow: hidden;

				&.slick-active {
					button {
						background: #000;
						color: #fff;
					}
				}

				button {
					color: #000;
					text-align: center;
					font-family: var(--fontFamilyBase);
					font-size: 13px;
					font-style: normal;
					font-weight: 600;
					line-height: 14px; /* 107.692% */
					letter-spacing: 0.4px;
					text-transform: uppercase;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					&:before {
						display: none;
					}
				}
			}
		}

		.slick-arrow {
			top: -30px;
			z-index: 9;
			display: flex;
			overflow: hidden;
			width: 24px;
			height: 24px;
			//text-indent: -9999px;
			font-size: 0;

			&:before {
				content: "";
				display: block;
				width: 24px;
				height: 24px;
				color: #090b0f;
				background: url(${arrowIcon}) no-repeat 50% 50%;
			}

			&.slick-prev {
				left: 10px;

				&:before {
					transform: rotate(180deg);
				}
			}

			&.slick-next {
				right: 10px;
			}
		}
	}
`;
const RoundBox = styled(Box)`
	border-top: 2px solid #000;
	border-bottom: 2px solid #000;
`;
const FContainer = styled(Box)`
	border-bottom: 2px solid #000;
	display: grid;
	grid-template-columns: repeat(11, 1fr);
	padding: 14px 0;
	//min-width: 335px;
	//overflow: auto;

	@media (max-width: 560px) {
		min-width: 40px;
	}
`;
const SContainer = styled(FContainer)`
	border-bottom: none;
	grid-template-columns: repeat(12, 1fr);
`;
const Column = styled(Box)`
	&.head {
		min-width: 80px;

		> div {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}
`;
const Cell = styled(Box)`
	padding: 6px 6px;
	color: #757575;
	text-align: center;
	font-family: var(--fontFamilyBase);
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 14px; /* 107.692% */
	letter-spacing: 0.4px;
	text-transform: uppercase;
	position: relative;

	@media (max-width: 480px) {
		font-size: 10px;
		line-height: 1.1;
		padding: 6px 2px;
	}

	&.bold {
		color: #000;
		font-family: var(--fontFamilyBase);
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 14px; /* 107.692% */
		letter-spacing: 0.4px;
		text-transform: uppercase;

		@media (max-width: 480px) {
			font-size: 10px;
			line-height: 1.1;
		}
	}

	//&.score{
	//	font-size: 12px;
	//}
`;

interface IProps {
	data: IScorecard | undefined;
	currentRoundIndex: number;
}

const defaultSettings: Settings = {
	arrows: true,
	dots: true,
	speed: 500,
	infinite: false,
	swipe: true,
	slidesToShow: 1,
	slidesToScroll: 1,
};

export const ScorecardTab: React.FC<IProps> = ({data, currentRoundIndex}) => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const sliderRef = useRef<Slider>(null);
	const settings: Settings = {
		...defaultSettings,
		initialSlide: currentRoundIndex,
	};

	if (!data?.rounds.length) {
		return (
			<Box>
				{i18n.t("player.modal.tabs.no_data", "Player has not completed a hole yet ...")}.
			</Box>
		);
	}

	return (
		<TabBox>
			<Slider ref={sliderRef} {...settings}>
				{data.rounds.map((it, index) => (
					<RoundBox key={index}>
						<FContainer>
							<Column className="head">
								<Cell>{i18n.t("player.modal.scorecard.hole_copy", "HOLE")}</Cell>
								<Cell>{i18n.t("player.modal.scorecard.par_copy", "PAR")}</Cell>
								<Cell className="bold">
									{i18n.t("player.modal.scorecard.score_copy", "SCORE")}
								</Cell>
								<Cell className="bold">
									{i18n.t("player.modal.scorecard.pts_copy", "PTS")}
								</Cell>
							</Column>

							{take(it.holes, 9).map((el, idx) => (
								<ColumnComponent key={idx} hole={el} />
							))}

							<Column>
								<Cell>{i18n.t("player.modal.scorecard.out_copy", "OUT")}</Cell>
								<Cell>{numberOrSlash(it.outPar)}</Cell>
								<Cell className="bold">{numberOrSlash(it.outScore)}</Cell>
								<Cell className="bold">{numberOrSlash(it.outPts)}</Cell>
							</Column>
						</FContainer>
						<SContainer>
							<Column className="head">
								<Cell>{i18n.t("player.modal.scorecard.hole_copy", "HOLE")}</Cell>
								<Cell>{i18n.t("player.modal.scorecard.par_copy", "PAR")}</Cell>
								<Cell className="bold">
									{i18n.t("player.modal.scorecard.score_copy", "SCORE")}
								</Cell>
								<Cell className="bold">
									{i18n.t("player.modal.scorecard.pts_copy", "PTS")}
								</Cell>
							</Column>

							{it.holes.slice(9).map((el, idx) => (
								<ColumnComponent key={idx} hole={el} />
							))}

							<Column>
								<Cell>{i18n.t("player.modal.scorecard.in_copy", "IN")}</Cell>
								<Cell>{numberOrSlash(it.inPar)}</Cell>
								<Cell className="bold">{numberOrSlash(it.inScore)}</Cell>
								<Cell className="bold">{numberOrSlash(it.inPts)}</Cell>
							</Column>

							<Column>
								<Cell>{i18n.t("player.modal.scorecard.tot_copy", "TOT")}</Cell>
								<Cell>{numberOrSlash(it.totPar)}</Cell>
								<Cell className="bold">{numberOrSlash(it.totScore)}</Cell>
								<Cell className="bold">{numberOrSlash(it.totPts)}</Cell>
							</Column>
						</SContainer>
					</RoundBox>
				))}
			</Slider>
		</TabBox>
	);
};

const SCircle = styled(Box)`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	border: 1px solid #949494;
	width: 20px;
	height: 20px;
`;
const MCircle = styled(SCircle)`
	width: 24px;
	height: 24px;
`;
const LCircle = styled(SCircle)`
	width: 28px;
	height: 28px;
`;
const SSquare = styled(Box)`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid #949494;
	width: 20px;
	height: 20px;
`;
const MSquare = styled(SSquare)`
	width: 24px;
	height: 24px;
`;
const LSquare = styled(SSquare)`
	width: 28px;
	height: 28px;
`;

interface IColumnProps {
	hole: IHole;
}

const ColumnComponent: React.FC<IColumnProps> = ({hole}) => {
	return (
		<Column>
			<Cell>{hole.number}</Cell>
			<Cell>{numberOrSlash(hole.par)}</Cell>
			<Cell className="bold score">
				<Exist when={gt(hole.finalParScoreDiff, 0)}>
					<Exist when={eq(hole.finalParScoreDiff, 1)}>
						<SCircle />
					</Exist>
					<Exist when={eq(hole.finalParScoreDiff, 2)}>
						<SCircle />
						<MCircle />
					</Exist>
					<Exist when={eq(hole.finalParScoreDiff, 3)}>
						<SCircle />
						<MCircle />
						<LCircle />
					</Exist>
				</Exist>
				<Exist when={lt(hole.finalParScoreDiff, 0)}>
					<Exist when={eq(hole.finalParScoreDiff, -1)}>
						<SSquare />
					</Exist>
					<Exist when={eq(hole.finalParScoreDiff, -2)}>
						<SSquare />
						<MSquare />
					</Exist>
					<Exist when={eq(hole.finalParScoreDiff, -3)}>
						<SSquare />
						<MSquare />
						<LSquare />
					</Exist>
				</Exist>
				{numberOrSlash(hole.score)}
			</Cell>
			<Cell className="bold">{numberOrSlash(hole.pts)}</Cell>
		</Column>
	);
};
