import {makeAutoObservable, observable, reaction} from "mobx";
import {inject, injectable} from "inversify";
import {defaultTo, first, get} from "lodash";
import {type RefObject, SyntheticEvent, useRef} from "react";
import {AxiosError, AxiosResponse} from "axios";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {IModalPayload, type IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import {ISegment, type ISegmentsStore} from "data/stores/segments/segments.store";
import {ITournament, type ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {
	type ICompareApiProvider,
	ITeamComparePayload,
	ITeamCompareResponse,
	ITeamPoints,
} from "data/providers/api/compare.api.provider";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {type ILeaguesStore} from "data/stores/leagues/leagues.store";

export type TournamentWithPoints = Partial<Omit<ITeamPoints, "tournamentId">> & ITournament;

export interface ICompareSegment extends ISegment {
	isDisabled: boolean;
}

export interface IModalCompareController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get modalContent(): IModalPayload | null;
	get segments(): ICompareSegment[];
	get selectedSegmentId(): number | null;
	get tournaments(): TournamentWithPoints[];
	get isLoading(): boolean;
	get ref(): RefObject<HTMLDivElement>;
	get teamsName(): {teamAName: string; teamBName: string};

	getIsOpen: (tournamentId: number) => boolean;
	toggleHandler: (tournamentId: number) => void;
	changeSegmentId: (event: SyntheticEvent, newValue: number) => void;
	close: () => void;
}

@injectable()
export class ModalCompareController implements IModalCompareController {
	@observable private _disposer?: ReturnType<typeof reaction>;
	@observable private _selectedSegmentId: number | null = null;
	@observable private _selectedTournamentId: number | null = null;
	@observable private _requestState = RequestState.IDLE;
	@observable private _teamsPoints: ITeamPoints[] = [];
	@observable private _ref = useRef<HTMLDivElement | null>(null);

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.COMPARE;
	}

	get teamsName() {
		return {
			teamAName: defaultTo(
				this._teamsPoints[0]?.teamAName,
				this.i18n.t("modal.compare.team_a", "Team A")
			),
			teamBName: defaultTo(
				this._teamsPoints[0]?.teamBName,
				this.i18n.t("modal.compare.team_b", "Team B")
			),
		};
	}

	get leagueTournaments(): number[] {
		const leagueId = this.modalContent?.leagueId;

		if (!leagueId) return [];

		return get(this._leaguesStore.getLeagueById(leagueId), "tournamentIds", []);
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get segments() {
		return this._segmentsStore.list.map((segment) => ({
			...segment,
			isDisabled: !this.leagueTournaments.some((id) => {
				const tournament = this._tournamentsStore.getTournamentById(id);

				return tournament?.seasonSegmentId === segment.id;
			}),
		}));
	}

	get selectedSegmentId() {
		return this._selectedSegmentId || 0;
	}

	get tournaments() {
		return this._tournamentsStore.list
			.map((tournament) => {
				const teamPoints = this._teamsPoints.find(
					({tournamentId}) => tournamentId === tournament.id
				);

				if (!teamPoints) return null;

				return {
					...tournament,
					teamAPoints: teamPoints.teamAPoints,
					teamBPoints: teamPoints.teamBPoints,
				};
			})
			.filter((tournament) => Boolean(tournament))
			.filter(
				(tournament) => tournament?.seasonSegmentId === this._selectedSegmentId
			) as TournamentWithPoints[];
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	private get requestParams(): ITeamComparePayload | void {
		const userId = this.modalContent?.userId;
		const leagueId = this.modalContent?.leagueId;

		if (!userId || !leagueId) return;

		return {
			userId,
			leagueId,
		};
	}

	private get initialSegmentId() {
		const defaultSegmentId = first(this._segmentsStore.list)?.id || null;

		let segmentId;
		if (this.modalContent?.tournamentId) {
			segmentId = this._tournamentsStore.getTournamentById(
				this.modalContent?.tournamentId
			)?.seasonSegmentId;
		} else {
			segmentId = this.modalContent?.segmentId;
		}

		return segmentId || defaultSegmentId;
	}

	private get initialTournamentId() {
		return this.modalContent?.tournamentId || null;
	}

	get ref() {
		return this._ref;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.SegmentsStore) private _segmentsStore: ISegmentsStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.CompareApiProvider) private _compareApiProvider: ICompareApiProvider,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	private onSuccess = (result: AxiosResponse<IApiResponse<ITeamCompareResponse>>) => {
		this._requestState = RequestState.SUCCESS;
		this._teamsPoints = result.data.success.teamsPoints;
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	private scrollToTournament(tournamentId: number | null) {
		if (!tournamentId) return;

		const element = this._ref?.current?.querySelector(
			`div[data-tournament-id*="${tournamentId}"]`
		);

		element?.scrollIntoView({block: "center"});
	}

	init() {
		this._disposer = reaction(
			() => this.isOpen,
			(isOpen) => {
				if (isOpen) {
					this._selectedSegmentId = this.initialSegmentId;
					this._selectedTournamentId = this.initialTournamentId;

					const params = this.requestParams;

					if (!params) return;

					this._requestState = RequestState.PENDING;

					this._compareApiProvider
						.compare(params)
						.then(this.onSuccess)
						.then(() => {
							this.scrollToTournament(this.initialTournamentId);
						})
						.catch(this.onError);
				}
			}
		);
	}

	changeSegmentId = (_: SyntheticEvent, newValue: number) => {
		this._selectedSegmentId = newValue;
	};

	close = () => {
		this._modalsStore.hideModal();
	};

	getIsOpen = (tournamentId: number) => this._selectedTournamentId === tournamentId;

	toggleHandler = (tournamentId: number) => {
		if (this._selectedTournamentId === tournamentId) {
			this._selectedTournamentId = null;
		} else {
			this._selectedTournamentId = tournamentId;
		}
	};

	dispose() {
		this._disposer?.();
	}
}
