import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import React, {type ChangeEvent} from "react";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {ILoginPayload} from "data/providers/api/auth.api.provider";
import type {IApiResponse} from "data/services/http";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {extractErrorMessage} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {useNavigate} from "react-router-dom";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

interface ILoginForm extends HTMLFormElement {
	email: HTMLInputElement;
	password: HTMLInputElement;
}

export interface ILoginFormController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get error(): Record<string, string> | null;
	get isFormDisabled(): boolean;
	get isSaveDisabled(): boolean;

	handleFormSubmit: (event: React.SyntheticEvent<ILoginForm>) => void;
	login: (params: ILoginPayload) => Promise<void>;
	handleFormOnChange: (event: ChangeEvent<ILoginForm>) => void;
}

@injectable()
export class LoginFormController implements ILoginFormController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable _navigate!: ReturnType<typeof useNavigate>;
	@observable private _email = "";
	@observable private _password = "";

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return this._requestState === RequestState.PENDING;
	}

	get isSaveDisabled() {
		return this._email === "" || this._password === "" || this.isFormDisabled;
	}

	@action init({navigate}: IProps) {
		this._navigate = navigate;
	}

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action handleFormOnChange = (event: ChangeEvent<ILoginForm>) => {
		if (event.target.name === "email") {
			this._email = event.target.value as string;
		}
		if (event.target.name === "password") {
			this._password = event.target.value as string;
		}
		this._errorMsg = null;
		this._errorPlace = "";
		this._requestState = RequestState.IDLE;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this.reportError(extractErrorMessage(error));
	};

	@action login(payload: ILoginPayload) {
		this._requestState = RequestState.PENDING;
		return this._userStore
			.login(payload)
			.then(() => {
				this._modalsStore.hideModal();
				this._navigate("leagues/");
			})
			.catch(this.onError);
	}

	@action handleFormSubmit = (event: React.SyntheticEvent<ILoginForm>) => {
		event.preventDefault();
		const {email, password} = event.currentTarget;

		if (!email.checkValidity()) {
			return this.reportError(
				this.i18n.t("login_form.email.error", "Please provide a valid email address"),
				"email"
			);
		}

		if (!password.checkValidity()) {
			return this.reportError(
				this.i18n.t("login_form.password.error", "Incorrect password. Please try again."),
				"password"
			);
		}

		void this.login({
			email: email.value,
			password: password.value,
		});
	};
}
