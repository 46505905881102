import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {AuthorizationSection, GigyaScreenType, ModalType} from "data/enums";
import type {IModalPayload, IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {SSO} from "data/utils/gigya_sso";
import {isAndroid, isIOS} from "react-device-detect";
import {Cookie} from "data/utils/cookie";

export interface IModalAuthorizationController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get currentAuthorizationSection(): AuthorizationSection;
	get modalContent(): IModalPayload | null;

	close: () => void;
	changeAuthorizationSection: (section: AuthorizationSection) => void;
	onGigyaLogin: () => void;
	onGigyaRegister: () => void;
}

@injectable()
export class ModalAuthorizationController implements IModalAuthorizationController {
	@observable private _currentSection: AuthorizationSection = AuthorizationSection.Login;
	private _cookies = new Cookie();
	_sso = new SSO();

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get currentAuthorizationSection() {
		return this._currentSection;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.AUTHORIZATION;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	close = () => {
		this._modalsStore.hideModal();
		this._currentSection = AuthorizationSection.Login;
	};

	private handleApp = (type: string) => {
		if (isAndroid) {
			window.android?.postMessage(JSON.stringify({action: type}));
		}

		if (isIOS) {
			window.webkit?.messageHandlers.ios.postMessage(JSON.stringify({action: type}));
		}
	};

	onGigyaLogin = () => {
		localStorage.setItem("save_team", "1");
		this._modalsStore.hideModal();

		if (this._cookies.isAppSSO()) {
			this.handleApp("promptLoginToUser");
		} else {
			this._sso.showScreenSet(GigyaScreenType.Login);
		}
	};
	onGigyaRegister = () => {
		localStorage.setItem("save_team", "1");
		this._modalsStore.hideModal();

		if (this._cookies.isAppSSO()) {
			this.handleApp("promptRegisterToUser");
		} else {
			this._sso.showScreenSet(GigyaScreenType.Register);
		}
	};

	@action
	changeAuthorizationSection = (section: AuthorizationSection) => {
		this._currentSection = section;
	};
}
