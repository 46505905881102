import React from "react";
import {observer} from "mobx-react";
import {Box, Button, Modal, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import CloseIcon from "@mui/icons-material/Close";
import type {IModalTutorialController} from "views/components/modals/modal_tutorial/modal_tutorial.controller";
import {every, partial, range, some} from "lodash";
import {Exist} from "views/components/exist/exist.component";
import {ReactComponent as ArrowIcon} from "assets/img/icons/arrow-icon.svg";
import {TUTORIAL_STEPS} from "data/constants";
import {ButtonPrimary, ButtonPrimaryInvert} from "views/components/buttons/buttons.component";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	outline: none;
	max-width: 600px;
	width: 100%;
	background: #fff;
	padding: 20px 20px 40px;
	border-radius: 4px;
	text-align: center;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}

	button {
		max-width: 300px;
		//color: #000;
	}

	p {
		color: #000;
		text-align: center;
		font-feature-settings:
			"clig" off,
			"liga" off;
		font-family: var(--fontFamilyBase);
		font-size: 18px;
		font-style: normal;
		font-weight: normal;
		line-height: 123%; /* 44.28px */
		letter-spacing: -0.7px;

		&.title {
			font-size: 36px;
			font-weight: 600;
			text-transform: capitalize;
		}
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	button {
		min-width: unset;
		width: 24px;
		height: 24px;
		border: 1px solid transparent;
		background: #000;
		border-radius: 50%;

		&:hover {
			border: 1px solid #000;
			svg {
				path {
					fill: #000;
				}
			}
		}

		svg {
			width: 16px;
			height: 16px;
			path {
				fill: #fff;
			}
		}
	}
`;
const ModalBody = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
`;
const TooltipDots = styled("div")`
	min-width: 84px;
	display: flex;
	justify-content: space-between;
	gap: 6px;
`;
const ButtonRow = styled("div")`
	padding-top: 15px;
	justify-content: space-between;
	display: flex;
	align-items: center;

	@media (max-width: 600px) {
		flex-direction: column;
		gap: 10px;
	}

	button {
		margin: 0 10px;
		width: 100%;
		min-width: 200px;
	}
`;

const TooltipDot = styled("span")<{isActive: boolean}>`
	box-sizing: border-box;
	height: 10px;
	width: ${({isActive}) => (isActive ? "20px" : "10px")};
	border-radius: ${({isActive}) => (isActive ? "5px" : "50%")};
	background: ${({isActive}) => (isActive ? "#000" : "#E5E5E5")};
`;
const TooltipControl = styled("div")`
	margin: 34px auto 0;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 14px;
`;
const ArrowContainer = styled("button")`
	width: 24px;
	height: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background: transparent;
	border: none;
	min-width: unset;

	&.prev {
		transform: rotate(180deg);
	}
`;
const ImageContainer = styled(Box)`
	width: 100%;
	min-height: 200px;
	margin-bottom: 20px;
`;
const ImagePlaceholder = styled(Box)`
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ff47d6;
	color: #000;
	margin-bottom: 20px;
`;

export const TutorialModal: React.FC = observer(() => {
	const {isOpen, i18n, isLastStep, activeStep, currentStep, close, setActiveStep} =
		useViewController<IModalTutorialController>(Bindings.ModalTutorialController);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<Button sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</Button>
				</CloseBlock>
				<ModalBody>
					{currentStep.image ? (
						<ImageContainer>
							<img src={currentStep.image} alt="step_img" />
						</ImageContainer>
					) : (
						<ImagePlaceholder>LOGO</ImagePlaceholder>
					)}

					<Typography className="title">{i18n.t(currentStep.title)}</Typography>

					<Typography>{i18n.t(currentStep.description)}</Typography>

					<Exist when={every([activeStep !== 0, !isLastStep])}>
						<TooltipControl>
							<ArrowContainer className="prev" onClick={partial(setActiveStep, -1)}>
								<ArrowIcon />
							</ArrowContainer>
							<TooltipDots>
								{range(TUTORIAL_STEPS.length).map((_, i) => (
									<TooltipDot key={i} isActive={activeStep === i} />
								))}
							</TooltipDots>
							<ArrowContainer className="next" onClick={partial(setActiveStep, 1)}>
								<ArrowIcon />
							</ArrowContainer>
						</TooltipControl>
					</Exist>

					<ButtonRow>
						<Exist when={activeStep === 0}>
							<ButtonPrimaryInvert onClick={partial(setActiveStep, 1)}>
								{i18n.t("tutorial.button.view_tutorial", "View Tutorial")}
							</ButtonPrimaryInvert>
						</Exist>
						<Exist when={some([activeStep === 0, isLastStep])}>
							<ButtonPrimary onClick={close}>
								{isLastStep
									? i18n.t("tutorial.button.start_copy", "Exit Tutorial")
									: i18n.t("tutorial.button.start_copy", "Start Playing")}
							</ButtonPrimary>
						</Exist>
					</ButtonRow>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
});
