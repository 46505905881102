import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface IKeyIndicatorController extends ViewController {
	readonly i18n: ILocalizationStore;
}

@injectable()
export class KeyIndicatorController implements IKeyIndicatorController {
	constructor(@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}
}
