import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeagues, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import {isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import {TrackAnalytics} from "data/utils/analytic_service";

export interface IMyLeaguesController extends ViewController {
	readonly i18n: ILocalizationStore;

	get leagues(): ILeagues;
	get isLoading(): boolean;

	loadMoreMyLeagues: () => void;
}

@injectable()
export class MyLeaguesController implements IMyLeaguesController {
	@observable _requestState = RequestState.PENDING;

	get leagues() {
		return this._leaguesStore.myLeagues;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	loadMoreMyLeagues = () => {
		this._leaguesStore.fetchMoreMyLeagues().then(this.onSuccess).catch(this.onError);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	private onLoadPageTrack = (section: string) => {
		const payload = {
			event: "Pageload",
			PageName: "leagues",
			PageSection: section,
			PreviousPageName: "",
			PreviousPageSection: "",
		};

		TrackAnalytics.trackOnLoad(payload);
	};

	@action init() {
		this.onLoadPageTrack("leagues");

		this._leaguesStore
			.fetchMyLeagues()
			.then(() => this._leaguesStore.fetchMyLeagues({isInactiveOnly: 1, limit: 1}))
			.then(this.onSuccess)
			.catch(this.onError);
	}
}
