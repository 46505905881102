import {retryFailLoad} from "data/utils";
import React, {Fragment, lazy} from "react";
import {Route} from "react-router-dom";

const SecretPage = lazy(retryFailLoad(() => import("views/pages/secret/secret.page")));

export const SecretGateRoutes = (
	<Fragment>
		<Route path="*" element={<SecretPage />} />
	</Fragment>
);

export default SecretGateRoutes;
