import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import {ChangeEvent, SyntheticEvent} from "react";
import {chain, some} from "lodash";
import type {IPoolPlayer} from "data/providers/api/tournament.provider";
import type {
	IFilter,
	IFilterForm,
	IPoolPlayersStore,
} from "data/stores/pool_players/pool_players.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IPlayerModalStore} from "data/stores/player_modal/player_modal.store";
import {PlayerStatus, PoolOrderedFields} from "data/enums";

export interface IPlayerPoolController extends ViewController {
	readonly i18n: ILocalizationStore;

	get filter(): IFilter;
	get players(): IPoolPlayer[];
	get fullTeam(): number[];
	get hasSearchValue(): boolean;
	get isPickDisabled(): boolean;
	get isCurrentLineupFull(): boolean;

	handleFormOnChange: (event: ChangeEvent<IFilterForm>) => void;
	handleFormSubmit: (event: SyntheticEvent<IFilterForm>) => void;
	onResetSearch: () => void;
	setOrderBy: (value: PoolOrderedFields) => void;
	onAddPlayer: (playerId: number) => void;
	onRemovePlayer: (playerId: number) => void;
	openPlayerModal: (playerId: number) => void;
}

@injectable()
export class PlayerPoolController implements IPlayerPoolController {
	get filter() {
		return this._poolPlayersStore.filter;
	}
	get players() {
		// const fullLineup = concat(this._teamStore.lineup, this._teamStore.bench);

		return chain(this._poolPlayersStore.list)
			.filter((player) => player.status !== PlayerStatus.Inactive)
			.filter(this.isListed)
			.orderBy([this.filter.orderBy], [this.filter.direction[this.filter.orderBy]])
			.value();
	}

	get fullTeam() {
		return this._teamStore.fullTeam;
	}

	get hasSearchValue() {
		return Boolean(this.filter.search.length);
	}

	get isPickDisabled() {
		if (this._tournamentsStore.isDisabledByCompleteRound) {
			return true;
		} else {
			return some([
				this._tournamentsStore.isSelectedTournamentDisabled,
				this._tournamentsStore.isDisabledChangeTeam,
				this._teamStore.hasPrevTeam,
			]);
		}
	}

	get isCurrentLineupFull() {
		return this._teamStore.isCurrentLineupFull;
	}

	private isListed = (player: IPoolPlayer) => {
		const {search} = this.filter;
		const fullName = `${player.firstName}{" "}${player.lastName}`;

		return !search || fullName.toLowerCase().includes(search.toLowerCase());
	};
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.PlayersStore) public _playersStore: IPlayersStore,
		@inject(Bindings.PoolPlayersStore) public _poolPlayersStore: IPoolPlayersStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore,
		@inject(Bindings.PlayerModalStore) public _playerModalStore: IPlayerModalStore
	) {
		makeAutoObservable(this);
	}

	@action onAddPlayer = (playerId: number) => {
		this._teamStore.addPlayer(playerId);
	};

	@action onRemovePlayer = (playerId: number) => {
		this._teamStore.removePlayer(playerId);
	};

	@action handleFormOnChange = (event: ChangeEvent<IFilterForm>) => {
		const {search} = event.currentTarget;
		this._poolPlayersStore.setSearch(search.value);
	};

	@action handleFormSubmit = (event: SyntheticEvent<IFilterForm>) => {
		event.preventDefault();
	};

	@action onResetSearch = () => {
		this._poolPlayersStore.setSearch("");
	};

	@action setOrderBy = (value: PoolOrderedFields) => {
		this._poolPlayersStore.setOrderBy(value);
		this._poolPlayersStore.setOrderDirection(value);
	};

	openPlayerModal = (playerId: number) => {
		this._playerModalStore.setPlayerId(playerId);
		this._playerModalStore.openPlayerModal();
	};
}
