export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ModalType {
	ERROR,
	CONFIRM,
	AUTHORIZATION,
	RESET_PASSWORD,
	SET_CAPTAIN,
	SECONDARY_REGISTRATION,
	COMPARE,
	LEAGUE_JOIN,
	TUTORIAL,
	TEAM_SAVED,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum OrderBy {
	TotalPoints = "totalPoints",
	SegmentPoints = "segmentPoints",
	TournamentPoints = "tournamentPoints",
}

export enum TournamentStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Completed = "completed",
	Canceled = "canceled",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Completed = "completed",
}

export enum RoundStatusFeed {
	SCHEDULED = "scheduled",
	GROUPINGS_OFFICIAL = "groupingsOfficial",
	NOT_STARTED = "notStarted",
	IN_PROGRESS = "inProgress",
	SUSPENDED = "suspended",
	PLAY_COMPLETE = "playComplete",
	OFFICIAL = "official",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum LeaguePrivacy {
	PUBLIC = "public",
	PRIVATE = "private",
}

export enum LeagueType {
	REGULAR = "regular",
	OVERALL = "overall",
}

export enum LeagueStatus {
	COMPLETED = "completed",
	PLAYING = "playing",
	SCHEDULED = "scheduled",
}

export enum NavigationSubMenuType {
	LEAGUES = "league",
	HELP = "help",
}

export enum AuthorizationSection {
	Login = "login",
	Registration = "registration",
	ForgotPassword = "Forgot Password",
}

export enum PlayerType {
	STARTER = "starter",
	BENCH = "bench",
}

export enum SaveButtonState {
	SAVE = "save",
	SAVING = "saving",
	SAVED = "saved",
	UPDATE = "update",
	UPDATING = "updating",
	UPDATED = "updated",
}

export enum GigyaScreenType {
	Register = "gigya-login-screen",
	Login = "gigya-fast-login",
}

export enum PlayerModalTabValue {
	SCORECARD = "scorecard",
	PERFORMANCE = "performance",
}
export enum PlayerScoreType {
	Default = "default",
	Score = "score",
	UnScore = "un_score",
}

export enum BonusType {
	HoleDoubleEagle = "doubleEagleBonus",
	HoleEagle = "eagleBonus",
	HoleBirdie = "birdieBonus",
	HolePar = "parBonus",
	HoleBogey = "bogeyBonus",
	HoleDoubleBogey = "doubleBogeyBonus",
	HoleRoundBonus = "endOfRoundBonus",
	HoleFedex = "fedexBonus",
}

export enum PageType {
	Home,
	Roster,
}

export enum PlayerStatus {
	Active = "active",
	Inactive = "inactive",
	Disqualified = "disqualified",
	Cut = "cut",
	Withdrawn = "withdrawn",
	MadeCutDidntFinish = "madeCutDidntFinish",
}

export enum PoolOrderedFields {
	ProjPts = "projPts",
	Cuts = "cuts",
	Top5s = "top5s",
	Top10s = "top10s",
	Wins = "wins",
	AvgPts = "avgPts",
	Owned = "owned",
	OddsToWin = "oddsToWin",
}
