import React, {Fragment, lazy} from "react";
import {Route} from "react-router-dom";
import {MainLayout} from "views/components/layouts/main_layout.component";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";
import {WidgetLayout} from "views/components/layouts/widget_layout.component";

// const Home = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));
const ResetPassword = lazy(
	retryFailLoad(() => import("views/pages/reset_password/reset_password.page"))
);
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ComponentsReview = lazy(
	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
);

const MyLeagues = lazy(retryFailLoad(() => import("views/pages/my_leagues/my_leagues.page")));
const JoinLeagues = lazy(retryFailLoad(() => import("views/pages/join_leagues/join_leagues.page")));
const CreateLeague = lazy(
	retryFailLoad(() => import("views/pages/create_league/create_league.page"))
);
const League = lazy(retryFailLoad(() => import("views/pages/league/league.page")));
const LeagueSettings = lazy(
	retryFailLoad(() => import("views/pages/league_settings/league_settings.page"))
);
const LeagueInvite = lazy(
	retryFailLoad(() => import("views/pages/league_invite/league_invite.page"))
);
const LeagueAbout = lazy(retryFailLoad(() => import("views/pages/league_about/league_about.page")));
const LeagueTable = lazy(retryFailLoad(() => import("views/pages/league_table/league_table.page")));
const LeagueManage = lazy(
	retryFailLoad(() => import("views/pages/league_manage/league_manage.page"))
);
const Backdoor = lazy(retryFailLoad(() => import("views/pages/backdoor/backdoor.page")));
const Game = lazy(retryFailLoad(() => import("views/pages/game/game.page")));
const Roster = lazy(retryFailLoad(() => import("views/pages/roster/roster.page")));
const Widget = lazy(retryFailLoad(() => import("views/pages/widget/widget.page")));
const WidgetPreview = lazy(
	retryFailLoad(() => import("views/pages/widget_preview/widget_preview.page"))
);
const Leaderboard = lazy(retryFailLoad(() => import("views/pages/leaderboard/leaderboard.page")));
const RegenerateLeague = lazy(
	retryFailLoad(() => import("views/pages/regenerate_league/regenerate_league.page"))
);

export const RootRoutes = (
	<Fragment>
		<Route element={<WidgetLayout />}>
			<Route path="/widget" element={<Widget />} />
		</Route>
		<Route element={<MainLayout />}>
			<Route index path="/" element={<Game />} />
			<Route path="/roster" element={<Roster />} />
			<Route path="/backdoor" element={<Backdoor />} />
			<Route path="/leagues/join" element={<JoinLeagues />}>
				<Route path=":code" element={<JoinLeagues />} />
			</Route>

			<Route element={<NotAuthOnlyRoute />}>
				<Route path="/reset-password" element={<ResetPassword />} />
			</Route>
			<Route element={<PrivateRoute />}>
				<Route path="/my-account" element={<MyAccount />} />
				<Route path="/rankings" element={<Leaderboard />} />
				<Route path="/leagues" element={<MyLeagues />} />
				<Route path="/leagues/create" element={<CreateLeague />} />
				<Route path="/leagues/regenerate" element={<RegenerateLeague />} />
				<Route path="/league/:leagueId" element={<League />}>
					<Route path="ladder" element={<LeagueTable />} />
					<Route path="invite" element={<LeagueInvite />} />
					<Route path="settings" element={<LeagueSettings />} />
					<Route path="about" element={<LeagueAbout />} />
					<Route path="manage" element={<LeagueManage />} />
				</Route>
			</Route>
			<Route path="/widget-preview" element={<WidgetPreview />} />
			<Route path="components" element={<ComponentsReview />} />
			<Route path="help/*" element={<Help />} />
			<Route path="*" element={<NotFound />} />
		</Route>
	</Fragment>
);

export default RootRoutes;
