import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITournament, ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {ChangeEvent} from "react";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {chain} from "lodash";
import {TournamentStatus} from "data/enums";

interface IProps {
	segmentId: number;
}
export interface ISegmentTournamentsListController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get tournamentsBySegmentId(): ITournament[];
	get tournamentIds(): number[];
	onSelect: (e: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class SegmentTournamentsListController implements ISegmentTournamentsListController {
	@observable private _segmentId: number = 0;

	get tournamentsBySegmentId() {
		return chain(this._tournamentsStore.list)
			.filter(
				(it) =>
					it.seasonSegmentId === this._segmentId &&
					it.status === TournamentStatus.Scheduled
			)
			.value();
	}

	get tournamentIds() {
		return this._leaguesStore.tournamentsIds;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	onSelect = (e: ChangeEvent<HTMLInputElement>) => {
		const {checked, value} = e.target;

		if (checked) {
			this._leaguesStore.addTournamentToList(Number(value));
		} else {
			this._leaguesStore.removeTournamentFromList(Number(value));
		}
	};

	init({segmentId}: IProps) {
		this._segmentId = segmentId;
	}
}
