import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore, ILeague} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface IMyLeaguesWidgetController extends ViewController {
	readonly i18n: ILocalizationStore;

	get myLeagues(): ILeague[];
}

@injectable()
export class MyLeaguesWidgetController implements IMyLeaguesWidgetController {
	get myLeagues() {
		return this._leaguesStore.myLeagues.leagues;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}
}
