import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILiveUpdatesStore} from "data/stores/live_updates/live_updates.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IPoolPlayersStore} from "data/stores/pool_players/pool_players.store";
import type {ILeaderboardsStore} from "data/stores/leaderboards/leaderboards.store";
import type {IPlayerModalStore} from "data/stores/player_modal/player_modal.store";

export interface ILiveScoreController extends ViewController {
	readonly i18n: ILocalizationStore;
}

@injectable()
export class LiveScoreController implements ILiveScoreController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.LiveUpdatesStore) public _liveUpdatesStore: ILiveUpdatesStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore,
		@inject(Bindings.PoolPlayersStore) public _poolPlayersStore: IPoolPlayersStore,
		@inject(Bindings.LeaderboardsStore) public _leaderboardsStore: ILeaderboardsStore,
		@inject(Bindings.PlayerModalStore) public _playerModalStore: IPlayerModalStore
	) {
		makeAutoObservable(this);
	}

	private setCurrentTournament = () => {
		this._tournamentsStore.setSelectedTournament(
			this._tournamentsStore.currentTournament ?? null
		);
	};

	dispose() {
		this._liveUpdatesStore.unsubscribe();
	}

	init() {
		const payload = {tournamentId: this._tournamentsStore.selectedTournamentId};

		void this._liveUpdatesStore.subscribe({
			fantasyTournaments: () =>
				this._tournamentsStore.fetchTournaments().then(this.setCurrentTournament),
			fantasyPlayerPool: () => this._poolPlayersStore.fetchPoolPlayers(payload),
			fantasyPlayerLeaderboard: () =>
				this._leaderboardsStore.fetchPlayersRanking(payload).then(() => {
					if (this._playerModalStore.isOpen) {
						void this._playerModalStore.fetchPlayerModal({
							...payload,
							playerId: this._playerModalStore.playerId,
						});
					}
				}),
			fantasyExpertTeams: () => this._tournamentsStore.fetchExpertTeams(payload),
			fantasyTeamsPoints: () => this._teamStore.fetchTeam(payload),
		});
	}
}
