import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ChangeEvent, SyntheticEvent} from "react";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {Cookie} from "data/utils/cookie";
import {isAndroid, isIOS} from "react-device-detect";

interface IMyAccountFormElement extends HTMLFormElement {
	displayName: HTMLInputElement;
	email: HTMLInputElement;
	isNotificationsEnabled: HTMLInputElement;
}

export interface IMyAccountController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isFormDisabled(): boolean;
	get error(): Record<string, string> | null;
	get displayName(): string;
	get user(): IUser;
	get isUpdateDisabled(): boolean;
	get isNotificationFormDisabled(): boolean;
	get isApp(): boolean;

	handleFormSubmit: (event: SyntheticEvent<IMyAccountFormElement>) => void;
	handleNotificationFormSubmit: (event: SyntheticEvent<IMyAccountFormElement>) => void;
	handleClearErrorOnChange: () => void;
	handleNotificationFormChange: () => void;
	handleInputDisplayNameValue: (event: ChangeEvent<HTMLInputElement>) => void;
	handleLogout: () => void;
	onLogout: () => void;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _displayName = "";
	@observable private _isFormChanged = false;
	@observable private _isNotificationFormChanged = false;
	private _cookies = new Cookie();

	get isUpdateDisabled() {
		return this.isFormDisabled || !this._isFormChanged;
	}

	get isNotificationFormDisabled() {
		return this.isFormDisabled || !this._isNotificationFormChanged;
	}

	get user() {
		return this._userStore.user!;
	}

	get displayName() {
		return this._displayName;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isApp() {
		return this._cookies.isAppSSO();
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
		this._displayName = this._userStore.user!.displayName;
	}

	@action handleInputDisplayNameValue = (event: ChangeEvent<HTMLInputElement>) => {
		this._displayName = event.target.value.replace("@", "");
	};

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action public handleFormSubmit = (event: SyntheticEvent<IMyAccountFormElement>) => {
		event.preventDefault();

		const {displayName} = event.currentTarget;

		const validateList = [
			// {field: email, error: "Please provide a valid email address", place: "email"},
			{field: displayName, error: "Please provide your display name", place: "username"},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		const payload = {
			displayName: displayName.value,
			// email: email.value,
		};

		this._requestState = RequestState.PENDING;
		this._userStore
			.update(payload)
			.then(this.onSuccess)
			.then(() =>
				runInAction(() => {
					this._isFormChanged = false;
				})
			)
			.catch(this.onError);
	};

	@action handleNotificationFormSubmit = (event: SyntheticEvent<IMyAccountFormElement>) => {
		event.preventDefault();

		const {isNotificationsEnabled} = event.currentTarget;

		const payload = {
			isNotificationsEnabled: isNotificationsEnabled.checked,
		};

		this._requestState = RequestState.PENDING;
		this._userStore
			.update(payload)
			.then(this.onSuccess)
			.then(() => {
				runInAction(() => {
					this._isNotificationFormChanged = false;
				});
			})
			.catch(this.onError);
	};

	@action handleNotificationFormChange = () => {
		this._isNotificationFormChanged = true;
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	private useDefaultLineup = () => {
		this._teamStore.clearLineup();
		localStorage.removeItem("save_team");
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	@action handleClearErrorOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._isFormChanged = true;
	};

	@action handleLogout = () => {
		this._requestState = RequestState.PENDING;
		void this._userStore
			.ssoLogout()
			.then(this.useDefaultLineup)
			.then(this.onSuccess)
			.catch(this.onError);
	};

	handleAppLogout = () => {
		if (isAndroid) {
			window.android?.postMessage({action: "promptLogoutToUser"});
		}

		if (isIOS) {
			window.webkit?.messageHandlers.ios.postMessage({action: "promptLogoutToUser"});
		}
	};

	onLogout = () => {
		if (this._cookies.isAppSSO()) {
			if (!this._cookies.isNeedAuthToApp()) {
				window.addEventListener("app-logout", this.handleAppLogout);
			}
		} else {
			this.handleLogout();
		}
	};
}
