import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Cookie} from "data/utils/cookie";

export interface IFooterController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isApp(): boolean;
}

@injectable()
export class FooterController implements IFooterController {
	private _cookies = new Cookie();

	get isApp() {
		return this._cookies.isAppSSO();
	}

	constructor(@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}
}
