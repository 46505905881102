import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore, IModalPayload} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";
import type {ITutorialStep} from "data/constants";

export interface IModalTutorialController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get activeStep(): number;
	get isLastStep(): boolean;
	get modalContent(): IModalPayload | null;
	get currentStep(): ITutorialStep;

	close: () => void;
	setActiveStep: (value: number) => void;
}

@injectable()
export class ModalTutorialController implements IModalTutorialController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TutorialStore) private readonly _tutorialStore: ITutorialStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TUTORIAL;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get activeStep() {
		return this._tutorialStore.activeStep;
	}

	get isLastStep() {
		return this._tutorialStore.isLastStep;
	}
	get currentStep() {
		return this._tutorialStore.currentStep;
	}

	close = () => {
		this._modalsStore.hideModal();
		this._tutorialStore.setActiveStep(0);
	};

	setActiveStep = (value: number) => {
		const nextStep = value > 0 ? this.activeStep + 1 : this.activeStep - 1;
		this._tutorialStore.setActiveStep(nextStep);
	};
}
