import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {RequestState} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {IPlayersStore} from "data/stores/players/players.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {ISegmentsStore} from "data/stores/segments/segments.store";

export interface IEntryPointsController extends ViewController {
	get isLoaded(): boolean;
}

@injectable()
export class EntryPointsController implements IEntryPointsController {
	@observable _requestState: RequestState = RequestState.IDLE;

	get isLoaded() {
		return this._requestState === RequestState.SUCCESS;
	}
	constructor(
		@inject(Bindings.SegmentsStore) private _segmentsStore: ISegmentsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	dispose() {
		return;
	}
	async init(param: void) {
		this._requestState = RequestState.PENDING;

		await Promise.all([
			this._segmentsStore.fetchSegments(),
			this._tournamentsStore.fetchTournaments(),
			this._playersStore.fetchPlayers(),
		])
			.then(() => {
				this._requestState = RequestState.SUCCESS;
			})
			.catch(() => {
				this._requestState = RequestState.ERROR;
			});
	}
}
