import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import {observer} from "mobx-react";
import {Button, Container, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useIsMobile} from "data/hooks";
import {Exist} from "views/components/exist/exist.component";
import {DesktopMenu} from "views/components/desktop_menu/desktop_menu.component";
import {MobileMenu} from "views/components/mobile_menu/mobile_menu.component";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IHeaderController} from "./header.controller";
import {NavLink} from "react-router-dom";
import {IAuthController} from "views/controllers/auth/auth.controller";
import {AccountCircleRounded} from "@mui/icons-material";
import {ReactComponent as LogoIcon} from "assets/img/pga_header_logo.svg";

const HeaderWrapper = styled.div`
	background: #ffffff;
	border-bottom: 1px solid #d6d4d2;
`;

const HeaderContent = styled.div`
	gap: 12px;
	//background: #151b34;
	height: 50px;

	.button-icon {
		color: #000;
		padding: 0;
	}

	@media (min-width: 768px) {
		padding: 10px 20px;
	}
`;

const SubHeader = styled(Container)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	svg {
		color: #000;
	}
`;

const MyAccountButton = styled(NavLink)<{isShow: boolean}>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	visibility: ${({isShow}) => (isShow ? "auto" : "hidden")};

	span {
		text-transform: none;
	}

	svg {
		margin-left: 5px;
	}
`;
const LoginButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;

	span {
		text-transform: none;
	}

	svg {
		margin-left: 5px;
	}
`;

export const Header: React.FC = observer(() => {
	const {isMobile} = useIsMobile();
	const {isAuthorized} = useViewController<IAuthController>(Bindings.AuthController);
	const {
		i18n,
		isOpen,
		toggleMenu,
		isShowBurgerButton,
		actualLinks,
		closeHandler,
		toggleTutorial,
		isAuth,
		onGigyaLogin,
	} = useViewController<IHeaderController>(Bindings.HeaderController, {
		isMobile,
	});

	return (
		<HeaderWrapper>
			<HeaderContent className="flex items-center">
				<SubHeader>
					<Exist when={isShowBurgerButton}>
						<IconButton
							sx={{paddingLeft: 0}}
							className="button-icon"
							onClick={toggleMenu}>
							{isOpen ? <CloseIcon /> : <MenuIcon />}
						</IconButton>
					</Exist>

					<NavLink to={"/"}>
						<LogoIcon />
					</NavLink>

					<MyAccountButton to={"/my-account"} isShow={isAuthorized}>
						<span className="hidden md:flex">
							{i18n.t("header.my_account", "My Account")}
						</span>
						<AccountCircleRounded />
					</MyAccountButton>

					<Exist when={!isAuthorized}>
						<LoginButton onClick={onGigyaLogin}>
							<span className="hidden md:flex">
								{i18n.t("header.login", "Login")}
							</span>
							<AccountCircleRounded />
						</LoginButton>
					</Exist>
				</SubHeader>
			</HeaderContent>
			<Container className="tutorial_step_2">
				<DesktopMenu
					showTutorial={toggleTutorial}
					menuItems={actualLinks}
					isAuth={isAuth}
				/>
				<MobileMenu
					menuItems={actualLinks}
					isAuth={isAuth}
					isOpen={isOpen}
					closeHandler={closeHandler}
					onLogin={onGigyaLogin}
				/>
			</Container>
		</HeaderWrapper>
	);
});
