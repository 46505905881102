import {MobileItem, MobileLinks} from "views/components/mobile_links/mobile_links.component";
import React from "react";
import styled from "@emotion/styled";
import {IMenuItems} from "data/types/navigation";
import {Button} from "@mui/material";

export const MobileMenuContainer = styled.div`
	background: #ffffff;
	height: 100vh;
	width: 100vw;
	position: absolute;
	left: -100vw;
	top: 50px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	transition: all 0.3s linear;
	z-index: 100;

	&.open {
		left: 0;
	}
	nav {
		width: 100%;
	}
`;

interface IMenu {
	isOpen: boolean;
	isAuth: boolean;
	menuItems: IMenuItems;
	closeHandler: () => void;
	onLogin: () => void;
}

export const MobileMenu: React.FC<IMenu> = ({menuItems, isOpen, isAuth, closeHandler, onLogin}) => {
	const actualLinks = isAuth ? menuItems.loggedIn.mobile : menuItems.loggedOut.mobile;

	const handleLogin = () => {
		onLogin();
		closeHandler();
	};

	return (
		<MobileMenuContainer className={isOpen ? "open" : ""}>
			<nav>
				<MobileLinks links={actualLinks} closeHandler={closeHandler} />
			</nav>
			{!isAuth && (
				<nav>
					<MobileItem>
						<Button onClick={handleLogin}>Log In</Button>
					</MobileItem>
				</nav>
			)}
		</MobileMenuContainer>
	);
};
