import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {
	IAPILogin,
	IAPIRegister,
	IPgaAppLogin,
	IPgaAppRegister,
} from "data/providers/api/user.api.provider";

export interface ILoginPayload {
	email: string;
	password: string;
}

type TLoginResponse = IApiResponse<{user: IUser}>;

export interface IAuthApiProvider {
	login: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	logout: () => Promise<AxiosResponse>;
	gigyaLogin: (params: IAPILogin) => Promise<AxiosResponse<TLoginResponse>>;
	gigyaRegister: (params: IAPIRegister) => Promise<AxiosResponse<TLoginResponse>>;
	gigyaLogout: () => Promise<AxiosResponse>;
	pgaAppLogin: (params: IPgaAppLogin) => Promise<AxiosResponse<TLoginResponse>>;
	pgaAppRegister: (params: IPgaAppRegister) => Promise<AxiosResponse<TLoginResponse>>;
	backdoor: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	login = (params: ILoginPayload) => this._http.post<TLoginResponse>("auth/login", params);

	logout = () => this._http.post("auth/logout");

	gigyaLogin = (params: IAPILogin) => this._http.post<TLoginResponse>(`auth/gigya/login`, params);
	gigyaRegister = (params: IAPIRegister) =>
		this._http.post<TLoginResponse>("auth/gigya/register", params);
	gigyaLogout = () => this._http.post("auth/gigya/logout");

	pgaAppLogin = (params: IPgaAppLogin) =>
		this._http.post<TLoginResponse>("auth/gigya/login-via-session", params);
	pgaAppRegister = (params: IPgaAppRegister) =>
		this._http.post<TLoginResponse>("auth/gigya/register-via-session", params);

	backdoor = (params: ILoginPayload) => this._http.post<TLoginResponse>("auth/backdoor", params);
}
