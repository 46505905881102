import {Box, styled} from "@mui/material";
import {IPerformance} from "data/stores/player_modal/player_modal.store";
import React from "react";
import {useViewController} from "data/services/locator";
import type {ILocalizationController} from "views/controllers/localization/localization.controller";
import {Bindings} from "data/constants/bindings";
import {numberOrZero} from "data/utils/helper";
import {map} from "lodash";

const TabBox = styled(Box)`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 6px;

	@media (max-width: 560px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 360px) {
		grid-template-columns: 1fr;
	}
`;
const TabCell = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 3px;
	align-items: flex-start;
	padding: 10px 2px;
`;
const CellTitle = styled(Box)`
	color: #949494;
	font-feature-settings:
		"clig" off,
		"liga" off;
	font-family: var(--fontFamilyBase);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%; /* 18.2px */
`;
const CellValue = styled(Box)`
	color: #000;
	font-feature-settings:
		"clig" off,
		"liga" off;
	font-family: var(--fontFamilyBase);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%; /* 18.2px */
`;
interface IProps {
	data: IPerformance | undefined;
}

export const PerformanceTab: React.FC<IProps> = ({data}) => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);

	if (!data) {
		return <Box>No Data available ....</Box>;
	}

	return (
		<TabBox>
			{map(data, (value, key) => (
				<TabCell key={key}>
					<CellTitle>{i18n.t(`player.modal.tabs.${key}`, key)}</CellTitle>
					<CellValue>{numberOrZero(value)}</CellValue>
				</TabCell>
			))}
			{/*<TabCell>*/}
			{/*	<CellTitle>{i18n.t("player.modal.tabs.project_pts", "Proj. Points")}</CellTitle>*/}
			{/*	<CellValue>{numberOrZero(data.projPts)}</CellValue>*/}
			{/*</TabCell>*/}
			{/*<TabCell>*/}
			{/*	<CellTitle>{i18n.t("player.modal.tabs.fantasy_pts", "Fantasy Points")}</CellTitle>*/}
			{/*	<CellValue>{numberOrZero(data.fantasyPts)}</CellValue>*/}
			{/*</TabCell>*/}
			{/*<TabCell>*/}
			{/*	<CellTitle>{i18n.t("player.modal.tabs.eagles", "Eagles")}</CellTitle>*/}
			{/*	<CellValue>{numberOrZero(data.eagles)}</CellValue>*/}
			{/*</TabCell>*/}
			{/*<TabCell>*/}
			{/*	<CellTitle>{i18n.t("player.modal.tabs.birdies", "Birdies")}</CellTitle>*/}
			{/*	<CellValue>{numberOrZero(data.birdies)}</CellValue>*/}
			{/*</TabCell>*/}
			{/*<TabCell>*/}
			{/*	<CellTitle>{i18n.t("player.modal.tabs.pars", "Pars")}</CellTitle>*/}
			{/*	<CellValue>{numberOrZero(data.pars)}</CellValue>*/}
			{/*</TabCell>*/}
			{/*<TabCell>*/}
			{/*	<CellTitle>{i18n.t("player.modal.tabs.bogeys", "Bogeys")}</CellTitle>*/}
			{/*	<CellValue>{numberOrZero(data.bogeys)}</CellValue>*/}
			{/*</TabCell>*/}
			{/*<TabCell>*/}
			{/*	<CellTitle>*/}
			{/*		{i18n.t("player.modal.tabs.double_bogeys", "Double Bogeys +")}*/}
			{/*	</CellTitle>*/}
			{/*	<CellValue>{numberOrZero(data.doubleBogeys)}</CellValue>*/}
			{/*</TabCell>*/}
		</TabBox>
	);
};
