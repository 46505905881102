import {NavigationSubMenuType} from "data/enums";
import {IMenuItems} from "data/types/navigation";
import {Cookie} from "data/utils/cookie";

export const PRIZES_LINK = "/help/prizes";

export const NON_AUTH_DESKTOP_LINKS = [
	{key: "navigation.home.copy", value: "Home", to: "/", isMobileVisible: true},
	{key: "navigation.roster.copy", value: "My Roster", to: "/roster", isMobileVisible: true},
	// {key: "navigation.help.prizes_copy", value: "Prizes", to: PRIZES_LINK, isMobileVisible: true},
	{
		key: "navigation.help.copy",
		value: "Help",
		to: "/help",
		isMobileVisible: true,
		// isEnd: (path: string) => path.includes("prizes"),
	},
];

export const RULES_LINK = "/help/rules";
export const MY_ACCOUNT_LINK = {
	key: "hamburger.my_account.copy",
	value: "My account",
	to: "/my-account",
};

export const DESKTOP_LINKS = [
	{key: "navigation.home.copy", value: "Home", to: "/", isMobileVisible: true},
	{key: "navigation.roster.copy", value: "My Roster", to: "/roster", isMobileVisible: true},
	{key: "navigation.leagues.copy", value: "Leagues", to: "/leagues", isMobileVisible: true},
	{key: "navigation.rankings.copy", value: "Rankings", to: "/rankings", isMobileVisible: true},
	// {key: "navigation.help.prizes_copy", value: "Prizes", to: PRIZES_LINK},
	{
		key: "navigation.help.copy",
		value: "Help",
		to: "/help",
		// isEnd: (path: string) => path.includes("prizes"),
	},
];

export const DESKTOP_LINKS_NO_RANK = DESKTOP_LINKS.filter((item) => item.to !== "/rankings");

export const LEAGUE_SUB_LINKS = [
	{key: "hamburger.my_leagues.copy", value: "Leagues", to: "/leagues"},
	{key: "hamburger.create_leagues.copy", value: "Create leagues", to: "/leagues/create"},
	{key: "hamburger.join_leagues.copy", value: "Join leagues", to: "/leagues/join"},
];

export const HELP_SUB_LINKS = [
	{key: "hamburger.guidelines.copy", value: "Guidelines", to: RULES_LINK},
	{key: "hamburger.faqs.copy", value: "FAQs", to: "/help/faqs"},
	{key: "hamburger.terms.copy", value: "Terms", to: "/help/t&cs"},
	{key: "hamburger.contact_us.copy", value: "Contact us", to: "/help/contact-us"},
	{key: "navigation.help.prizes_copy", value: "Prizes", to: PRIZES_LINK},
];

export const NON_AUTH_MOBILE_LINKS = [
	{key: "hamburger.home.copy", value: "Home", to: "/"},
	{key: "hamburger.roster.copy", value: "My Roster", to: "/roster"},
	// {key: "hamburger.prizes.copy", value: "Prizes", to: PRIZES_LINK},
	{
		key: "hamburger.help.copy",
		value: "Help",
		to: RULES_LINK,
		submenuType: NavigationSubMenuType.HELP,
		submenu: HELP_SUB_LINKS,
		isEnd: (path: string) => path.includes("prizes"),
	},
];

export const MOBILE_LINKS = [
	{key: "hamburger.home.copy", value: "Home", to: "/"},
	{key: "hamburger.roster.copy", value: "My Roster", to: "/roster"},
	{
		key: "hamburger.leagues.copy",
		value: "Leagues",
		to: "/leagues",
		submenuType: NavigationSubMenuType.LEAGUES,
		submenu: LEAGUE_SUB_LINKS,
	},
	{key: "hamburger.rankings.copy", value: "Rankings", to: "/rankings"},
	// {key: "hamburger.prizes.copy", value: "Prizes", to: PRIZES_LINK},
	{
		key: "hamburger.help.copy",
		value: "Help",
		to: RULES_LINK,
		isEnd: (path: string) => path.includes("prizes"),
		submenuType: NavigationSubMenuType.HELP,
		submenu: HELP_SUB_LINKS,
	},
	MY_ACCOUNT_LINK,
];

const cookies = new Cookie();
if (!cookies.isAppSSO()) {
	MOBILE_LINKS.push({key: "hamburger.logout.copy", value: "Logout", to: "/logout"});
}

export const NAV_LINKS: IMenuItems = {
	loggedIn: {
		desktop: DESKTOP_LINKS,
		mobile: MOBILE_LINKS,
	},
	loggedOut: {
		desktop: NON_AUTH_DESKTOP_LINKS,
		mobile: NON_AUTH_MOBILE_LINKS,
	},
};
