import {makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITournament, ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {some} from "lodash";
import {IRound} from "data/stores/rounds/rounds.store";
import {RoundStatusFeed, ShareType, SocialNetwork} from "data/enums";
import type {IUserStore} from "data/stores/user/user.store";
import {TrackAnalytics} from "data/utils/analytic_service";
import {share} from "data/utils";

export interface ITeamBuilderController extends ViewController {
	readonly i18n: ILocalizationStore;

	get fullLineup(): number[];
	get starters(): number[];
	get bench(): number[];
	get isCurrentLineupFull(): boolean;
	get isSelectedTournamentDisabled(): boolean;
	get isDisabledByCompleteRound(): boolean;
	get isDisabledEditBtn(): boolean;
	get selectedTournament(): ITournament | null;
	get currentRound(): IRound | undefined;
	get isEditTeam(): boolean;
	get disabledByCurrentRoundTitle(): string;
	get userName(): string;
	get isAuth(): boolean;
	get isMobileShareVisible(): boolean;
	get hasTeam(): boolean;
	get hasPrevTeam(): boolean;
	get selectedRound(): IRound;

	onEditTeam: () => void;
	onTrackAction: () => void;
	shareViaFB: () => void;
	shareViaTW: () => void;
	shareViaMobile: () => void;
}

@injectable()
export class TeamBuilderController implements ITeamBuilderController {
	private _disposerReaction!: ReturnType<typeof reaction>;
	@observable private _isNarrowScreen: boolean = false;

	get fullLineup() {
		return [...this._teamStore.lineup, ...this._teamStore.bench];
	}
	get starters() {
		return this._teamStore.lineup;
	}
	get bench() {
		return this._teamStore.bench;
	}

	get isCurrentLineupFull() {
		return this._teamStore.isCurrentLineupFull;
	}

	get isSelectedTournamentDisabled() {
		return this._tournamentsStore.isSelectedTournamentDisabled;
	}

	get isDisabledByCompleteRound() {
		return this._tournamentsStore.isDisabledByCompleteRound;
	}

	get isDisabledEditBtn() {
		return some([!this.isCurrentLineupFull, this.isSelectedTournamentDisabled]);
	}

	get selectedTournament() {
		return this._tournamentsStore.selectedTournament;
	}
	get currentRound() {
		return this._tournamentsStore.currentRound;
	}

	get selectedRound() {
		return this._tournamentsStore.selectedRound;
	}

	get isEditTeam() {
		return this._teamStore.isEditTeam;
	}

	get disabledByCurrentRoundTitle() {
		const currentRound = this.currentRound;

		if (!currentRound) return "";

		const statusText = {
			[RoundStatusFeed.SCHEDULED]: `is ready for select team`,
			[RoundStatusFeed.NOT_STARTED]: `is ready for select team`,
			[RoundStatusFeed.GROUPINGS_OFFICIAL]: `is ready for select team`,
			[RoundStatusFeed.SUSPENDED]: `is playing, come back later`,
			[RoundStatusFeed.IN_PROGRESS]: `is playing, come back later`,
			[RoundStatusFeed.OFFICIAL]: `is completed`,
			[RoundStatusFeed.PLAY_COMPLETE]: `is completed`,
		}[currentRound?.status ?? RoundStatusFeed.GROUPINGS_OFFICIAL];

		return this.i18n.t(
			`team.team_builder.round_${currentRound.status}`,
			`Round {{X}} ${statusText}`,
			{X: currentRound.name}
		);
	}

	get userName() {
		return this._userStore.user?.displayName || "";
	}

	get isAuth() {
		return this._userStore.isAuthorized;
	}

	get isMobileShareVisible() {
		return "share" in navigator && this._isNarrowScreen;
	}

	get hasTeam() {
		return this._teamStore.hasTeam;
	}

	get hasPrevTeam() {
		return this._teamStore.hasPrevTeam;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	onEditTeam = () => {
		this._teamStore.setEditTeam(!this.isEditTeam);
		this.onTrackAction();
	};

	onTrackAction = () => {
		const payload = {
			event: "Button Clicked",
			SelectionName: "edit",
			SelectionTournamentId: this.selectedTournament?.id ?? 0,
		};

		TrackAnalytics.trackOnAction(payload);
	};

	private share(socialNetwork: SocialNetwork) {
		share({
			teamId: String(this._teamStore.team.id),
			socialNetwork,
			type: ShareType.General,
			lang: this.i18n.lang,
			message: this.i18n.t("team.share.message", "Share team message"),
		});
	}

	shareViaFB = () => this.share(SocialNetwork.Facebook);

	shareViaTW = () => this.share(SocialNetwork.Twitter);

	shareViaMobile = () => this.share(SocialNetwork.Mobile);

	dispose() {
		this._disposerReaction?.();
	}

	init(param: void) {
		return;
		// this._disposerReaction = reaction(
		// 	() => this._tournamentsStore.isDisabledByCompleteRound,
		// 	(isDisabled) => {
		// 		if (isDisabled) {
		// 			this.onEditTeam();
		// 		}
		// 	},
		// 	{fireImmediately: false}
		// );
	}
}
