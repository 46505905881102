import {action, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {type IMenuItems} from "data/types/navigation";
import {NAV_LINKS} from "data/constants/navigation";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {SSO} from "data/utils/gigya_sso";
import {GigyaScreenType, ModalType} from "data/enums";
import {Cookie} from "data/utils/cookie";
import {isAndroid, isIOS} from "react-device-detect";
import type {IModalsStore} from "data/stores/modals/modals.store";

interface IProps {
	isMobile: boolean;
}

export interface IHeaderController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get isOpen(): boolean;
	get isShowBurgerButton(): boolean;
	get actualLinks(): IMenuItems;
	get isAuth(): boolean;

	toggleMenu: () => void;
	closeHandler: () => void;
	toggleTutorial: () => void;
	onGigyaLogin: () => void;
}

@injectable()
export class HeaderController implements IHeaderController {
	@observable private _isOpen: boolean = false;
	@observable private _isMobile: boolean = false;
	private _cookies = new Cookie();
	_sso = new SSO();

	get isOpen() {
		return this._isOpen;
	}

	get actualLinks() {
		return NAV_LINKS;
	}

	private get actualMobileLinks() {
		return this.isAuth ? this.actualLinks.loggedIn.mobile : this.actualLinks.loggedOut.mobile;
	}

	get isShowBurgerButton() {
		return Boolean(this._isMobile && this.actualMobileLinks.length);
	}

	get isAuth() {
		return this._userStore.isAuthorized;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) public _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action init(params: IProps) {
		this.onChange(params);
		window.addEventListener("resize", this.closeHandler);
	}

	@action onChange({isMobile}: IProps) {
		this._isMobile = isMobile;
	}

	toggleMenu = () => {
		this._isOpen = !this._isOpen;
	};

	closeHandler = () => {
		this._isOpen = false;
	};

	toggleTutorial = () => {
		this._modalsStore.showModal(ModalType.TUTORIAL, {
			message: "",
		});
	};

	handleAppLogin = () => {
		if (isAndroid) {
			window.android?.postMessage(JSON.stringify({action: "promptLoginToUser"}));
		}

		if (isIOS) {
			window.webkit?.messageHandlers.ios.postMessage(
				JSON.stringify({action: "promptLoginToUser"})
			);
		}
	};

	onGigyaLogin = () => {
		if (this._cookies.isAppSSO()) {
			// if (!this._cookies.isNeedAuthToApp()) {
			// 	window.addEventListener("app-login", this.handleAppLogin);
			// }
			this.handleAppLogin();
		} else {
			this._sso.showScreenSet(GigyaScreenType.Login);
		}
	};
}
