import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";

export interface IPlayerModalPayload {
	tournamentId: number;
	playerId: number;
}

export interface IPlayerModalResponse {
	scorecard: IScorecard;
	performance: IPerformance;
}

export interface IScorecard {
	rounds: IScorecardRound[];
	roundScore: number | null;
	total: number | null;
	thru: string | null;
	totalPts: number | null;
	endRoundBonusPts: number | null;
	fedexPts: number | null;
	teeTime: string | null;
}

export interface IScorecardRound {
	name: string;
	holes: IHole[];
	inPar: number;
	outPar: number;
	totPar: number;
	inScore: number;
	outScore: number;
	totScore: number;
	inPts: number;
	outPts: number;
	totPts: number;
}

export interface IHole {
	finalParScoreDiff: number;
	number: number;
	par: number | null;
	score: number | null;
	pts: number | null;
}

export interface IPerformance {
	projPts: number | null;
	fantasyPts: number | null;
	eagles: number | null;
	birdies: number | null;
	pars: number | null;
	bogeys: number | null;
	doubleBogeys: number | null;
	cuts: number | null;
	avgPts: number | null;
	top10s: number | null;
	top5s: number | null;
	wins: number | null;
}

export interface IPlayerModalStore {
	get isLoading(): boolean;
	get isOpen(): boolean;
	get playerId(): number;
	get playerData(): IPlayerModalResponse | null;

	openPlayerModal(): void;
	closePlayerModal(): void;
	setPlayerId(playerId: number): void;
	fetchPlayerModal(payload: IPlayerModalPayload): Promise<void>;
}

@injectable()
export class PlayerModalStore implements IPlayerModalStore {
	@observable private _isOpen: boolean = false;
	@observable private _isLoading: boolean = false;
	@observable private _playerId: number = 0;
	@observable private _playerData: IPlayerModalResponse | null = null;

	get isLoading() {
		return this._isLoading;
	}
	get isOpen() {
		return this._isOpen;
	}

	get playerId() {
		return this._playerId;
	}

	get playerData() {
		return this._playerData;
	}

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@action openPlayerModal() {
		this._isOpen = true;
	}

	@action closePlayerModal() {
		this._isOpen = false;
	}

	@action setPlayerId(playerId: number) {
		this._playerId = playerId;
	}

	@action
	async fetchPlayerModal(payload: IPlayerModalPayload) {
		this._isLoading = true;
		const {data} = await this._jsonProvider.playerModal(payload);

		runInAction(() => {
			this._playerData = data;
			this._isLoading = false;
		});
	}
}
