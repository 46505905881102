import {action, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type IMenuItemWithSubLinks} from "data/types/navigation";

interface IController {
	link: IMenuItemWithSubLinks;
	pathname: string;
}

export interface IMobileLinkController extends ViewController<IController> {
	readonly i18n: ILocalizationStore;

	get open(): boolean;

	get topLevelHighlight(): string;

	get isSelected(): boolean;

	toggleClick: () => void;
}

@injectable()
export class MobileLinkController implements IMobileLinkController {
	@observable _link: IMenuItemWithSubLinks | undefined;
	@observable _open: boolean = false;
	@observable _pathname: string = "";

	constructor(@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	get open() {
		return this._open;
	}

	get topLevelHighlight() {
		return this._link?.topLevel ? "highlight" : "";
	}

	get isSelected() {
		if (this._link?.external) {
			return false;
		}
		const isEnd = this._link?.isEnd?.(this._pathname);
		return this._link?.submenuType && !isEnd
			? this._pathname.includes(this._link.submenuType)
			: false;
	}

	@action toggleClick = () => {
		this._open = !this._open;
	};

	onChange(param: IController) {
		this._pathname = param.pathname;
	}

	init(param: IController): void {
		this._link = param.link;
		this._pathname = param.pathname;
	}
}
