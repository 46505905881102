import {inject, injectable} from "inversify";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {GAME_SLUG} from "data/constants";
import {PlayerStatus} from "data/enums";

export interface ITournamentPlayersPayload {
	tournamentId: number;
}

export interface ITournamentPlayersResponse {
	playerPool: IPoolPlayer[];
	nextPage: boolean;
}

export interface IPoolPlayer {
	id: number;
	status: PlayerStatus;
	firstName: string;
	lastName: string;
	headshot: string;
	projPts: number | null;
	cuts: number | null;
	top5s: number | null;
	top10s: number | null;
	wins: number | null;
	avgPts: number | null;
	owned: number | null;
	oddsToWin: string | null;
	thru: string | null;
	teeTime: string | null;
	tot: number | null;
	fedexPts: number | null;
	pts: number | null;
	// r1Pts: number | null;
	// r2Pts: number | null;
	// r3Pts: number | null;
	// r4Pts: number | null;
	// r1ProjPts: number | null;
	// r2ProjPts: number | null;
	// r3ProjPts: number | null;
	// r4ProjPts: number | null;
	// r1IsPlayed: boolean;
	// r2IsPlayed: boolean;
	// r3IsPlayed: boolean;
	// r4IsPlayed: boolean;

	roundStats: IRoundStat[];
}

export interface IRoundStat {
	roundId: number;
	points: number | null;
	projPoints: number | null;
	thru: string | null;
	teeTime: string | null;
	isRoundPlayed: boolean;
}

export interface IUsesResponse {
	playersStats: IUsesPlayer[];
}

export interface IUsesPlayer {
	id: number;
	usagesCount: number;
	usagesLimit: number;
}

export interface ITournamentPlayersProvider {
	tournament_players: (
		params: ITournamentPlayersPayload
	) => Promise<AxiosResponse<IApiResponse<ITournamentPlayersResponse>>>;
	uses_players: (
		params: ITournamentPlayersPayload
	) => Promise<AxiosResponse<IApiResponse<IUsesResponse>>>;
}

@injectable()
export class TournamentPlayersProvider implements ITournamentPlayersProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}
	tournament_players = ({tournamentId, ...params}: ITournamentPlayersPayload) =>
		this._http.get<IApiResponse<ITournamentPlayersResponse>>(
			`${GAME_SLUG}/tournament/${tournamentId}/player-pool`,
			params
		);
	uses_players = ({tournamentId}: ITournamentPlayersPayload) =>
		this._http.get<IApiResponse<IUsesResponse>>(
			`${GAME_SLUG}/user-player/tournament/${tournamentId}/stats/`
		);
}
