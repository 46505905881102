import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeaguesStore, ILeagues} from "data/stores/leagues/leagues.store";
import {ModalType, RequestState} from "data/enums";
import {isEqual} from "lodash";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IRegenerateLeagueController extends ViewController {
	readonly i18n: ILocalizationStore;

	get myInactiveLeagues(): ILeagues;
	get isLoading(): boolean;

	loadMoreMyLeagues: () => void;
}

@injectable()
export class RegenerateLeagueController implements IRegenerateLeagueController {
	@observable _requestState = RequestState.PENDING;

	get myInactiveLeagues() {
		return this._leaguesStore.myInactiveLeagues;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}
	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	loadMoreMyLeagues = () => {
		this._leaguesStore
			.fetchMoreMyLeagues({isInactiveOnly: 1})
			.then(this.onSuccess)
			.catch(this.onError);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action init() {
		this._leaguesStore
			.fetchMyLeagues({isInactiveOnly: 1})
			.then(this.onSuccess)
			.catch(this.onError);
	}
}
