import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";

export interface ILineupRowController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isOpen(): boolean;

	toggleHandler: () => void;
}

@injectable()
export class LineupRowController implements ILineupRowController {
	@observable private _isOpen: boolean = false;

	get isOpen() {
		return this._isOpen;
	}

	constructor(@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	toggleHandler = () => {
		this._isOpen = !this._isOpen;
	};
}
