/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";

export const buttonsTheme: ThemeOptions = {
	components: {
		// MuiButton: {
		// 	defaultProps: {
		// 		disableElevation: true,
		// 		variant: "contained",
		// 	},
		// 	styleOverrides: {},
		// 	variants: [
		// 		{
		// 			props: {variant: "contained", color: "primary"},
		// 			style: {
		// 				borderRadius: "100px",
		// 				background: "#0000DC",
		// 				color: "#ffffff",
		// 				fontWeight: "700",
		// 				height: "48px",
		// 				textTransform: "none",
		// 				fontSize: "16px",
		// 				boxShadow: "none",
		// 				"&:disabled": {
		// 					background: "#E6ECFF",
		// 					color: "#636366",
		// 					cursor: "inherit",
		// 				},
		// 				"&:hover": {
		// 					background: "#4E7BF5",
		// 				},
		// 			},
		// 		},
		// 		{
		// 			props: {variant: "outlined", color: "primary"},
		// 			style: {
		// 				borderRadius: "100px",
		// 				background: "transparent",
		// 				color: "#151B34",
		// 				borderColor: "#151B34",
		// 				fontWeight: "700",
		// 				height: "48px",
		// 				textTransform: "none",
		// 				fontSize: "16px",
		// 				boxShadow: "none",
		// 				borderWidth: "2px",
		// 				"&:disabled": {
		// 					borderColor: "#F4F7FF",
		// 					background: "#F4F7FF",
		// 					color: "#636366",
		// 					cursor: "inherit",
		// 				},
		// 				"&:hover": {
		// 					borderColor: "#373B57",
		// 					background: "#373B57",
		// 					color: "#fff",
		// 				},
		// 			},
		// 		},
		// 		{
		// 			props: {variant: "outlined", color: "error"},
		// 			style: {
		// 				letterSpacing: "normal",
		// 				color: "#E40000",
		// 				borderColor: "#E40000",
		// 			},
		// 		},
		// 		{
		// 			props: {className: "menu-button"},
		// 			style: {
		// 				height: "48px",
		// 				backgroundColor: "#25347B",
		// 			},
		// 		},
		// 	],
		// },
	},
};
