import {action, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalPayload, IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState, SaveButtonState} from "data/enums";
import {isEqual, some} from "lodash";
import {ChangeEvent, SyntheticEvent} from "react";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {IUserStore} from "data/stores/user/user.store";
import {IAPILogin} from "data/providers/api/user.api.provider";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {Cookie} from "data/utils/cookie";
import {TrackAnalytics} from "data/utils/analytic_service";

interface IRegistrationFormElement extends HTMLFormElement {
	username: HTMLInputElement;
	terms: HTMLInputElement;
	isNotificationsEnabled: HTMLInputElement;
}

interface IFormData {
	displayName: string;
	terms: boolean;
	isNotificationsEnabled: boolean;
	country: string | null;
}

export interface IModalSecondaryRegistrationController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;

	get modalContent(): IModalPayload | null;

	get isFormDisabled(): boolean;

	get isFormDisabledByData(): boolean;

	get error(): Record<string, string> | null;

	get formData(): IFormData;

	close: () => void;
	handleFormSubmit: (event: SyntheticEvent<IRegistrationFormElement>) => void;
	handleFormOnChange: (event: ChangeEvent<IRegistrationFormElement>) => void;
	handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
	handleInputUserNameValue: (event: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class ModalSecondaryRegistrationController implements IModalSecondaryRegistrationController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _formData: IFormData = {
		displayName: "",
		terms: false,
		isNotificationsEnabled: false,
		country: null,
	};
	private _cookies = new Cookie();
	private _disposerReaction!: ReturnType<typeof reaction>;

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get formData(): IFormData {
		return this._formData;
	}

	get isFormDisabled(): boolean {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isFormDisabledByData(): boolean {
		return some([!this._formData.terms, !this._formData.displayName.length]);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.SECONDARY_REGISTRATION;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	@action
	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: this._errorMsg,
		});
	};

	@action public handleFormSubmit = (event: SyntheticEvent<IRegistrationFormElement>) => {
		event.preventDefault();

		const {terms, username} = event.currentTarget;

		const errorInvalidUsername = this.i18n.t(
			"registration.display_name.error",
			"Please provide your display name"
		);

		const errorInvalidTerms = this.i18n.t(
			"registration.terms.error",
			"Please accept Terms & Conditions"
		);

		const validateList = [
			{field: username, error: errorInvalidUsername, place: "username"},
			{field: terms, error: errorInvalidTerms, place: "terms"},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		this._requestState = RequestState.PENDING;

		if (this._cookies.isAppSSO()) {
			this.useAppRegister();
		} else {
			this.useWebRegister();
		}
	};

	private useAppRegister = () => {
		void this._userStore
			.ssoAppRegister({
				...this._cookies.pgaAppPayload(),
				displayName: this._formData.displayName,
				isNotificationsEnabled: this._formData.isNotificationsEnabled,
			})
			.then(() => {
				this.close();
			})
			.then(() => this.saveLocalTeam())
			.catch((error: AxiosError<IApiResponse>) => {
				this._errorMsg = extractErrorMessage(error);
				this.reportError(this._errorMsg, "username");
				this._requestState = RequestState.IDLE;
			});
	};

	private useWebRegister = () => {
		void this.getSSOUser()
			.then(() =>
				this._userStore.ssoWebRegister({
					...this._userStore.userLoginParams,
					displayName: this._formData.displayName,
					isNotificationsEnabled: this._formData.isNotificationsEnabled,
				})
			)
			.then(() => {
				this.close();
			})
			.then(() => {
				this.saveLocalTeam();
			})
			.catch((error: AxiosError<IApiResponse>) => {
				this._errorMsg = extractErrorMessage(error);
				this.reportError(this._errorMsg, "username");
				this._requestState = RequestState.IDLE;
			});
	};

	showTeamSavedModal = () => {
		this._modalsStore.showModal(ModalType.TEAM_SAVED, {
			message: this.i18n.t(
				"modal.team_saved.message",
				"You can make edits to your team up until the start of the first Round"
			),
		});
	};

	private saveLocalTeam = () => {
		const hasUnsavedTeam = Boolean(JSON.parse(localStorage.getItem("save_team") || "null"));

		if (hasUnsavedTeam && this._userStore.isAuthorized) {
			void this._teamStore
				.saveTeam({
					tournamentId: this._tournamentsStore.selectedTournamentId,
					roundId: this._tournamentsStore.selectedRound.id,
					...this._teamStore.saveTeamPayload,
				})
				.then(() => localStorage.removeItem("save_team"))
				.then(this.showTeamSavedModal)
				.catch((error: AxiosError<IApiResponse>) => {
					this._modalsStore.showModal(ModalType.ERROR, {
						message: extractErrorMessage(error),
					});

					this._teamStore.setSaveState(SaveButtonState.SAVE);
				});
		}
	};

	@action private getSSOUser = () => {
		return new Promise<IAPILogin>((resolve, reject) => {
			window.gigya?.accounts.getAccountInfo({
				include: "id_token",
				callback: (resp) => {
					if (resp.errorCode) {
						runInAction(() => {
							reject(resp);
						});

						return;
					}

					runInAction(() => {
						const loginParam = {
							uid: resp.UID,
							uidSignature: resp.UIDSignature,
							signatureTimestamp: resp.signatureTimestamp,
						};

						this._userStore.setUserParams(loginParam);
						this._userStore.setUserProfile(resp.profile);

						resolve({...loginParam});
					});
				},
			});
		});
	};

	@action handleFormOnChange = (event: ChangeEvent<IRegistrationFormElement>) => {
		if (event.target.name !== "password") {
			this._errorMsg = null;
			this._errorPlace = "";
		}
	};

	@action handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
		const {checked, name} = event.target;

		if (name === "terms") {
			this._formData.terms = checked;
		}

		if (name === "isNotificationsEnabled") {
			this._formData.isNotificationsEnabled = checked;
		}
	};

	@action handleInputUserNameValue = (event: ChangeEvent<HTMLInputElement>) => {
		this._formData.displayName = event.target.value.replace("@", "");
	};

	close = () => {
		this._modalsStore.hideModal();
	};

	private onLoadPageTrack = () => {
		const payload = {
			event: "Pageload",
			PageName: "home",
			PageSection: "registation",
			PreviousPageName: "",
			PreviousPageSection: "",
		};

		TrackAnalytics.trackOnLoad(payload);
	};

	dispose() {
		this._disposerReaction?.();
	}

	init(param: void) {
		this._disposerReaction = reaction(
			() => this.isOpen,
			(isOpen) => {
				if (isOpen) {
					this.onLoadPageTrack();
				}
			},
			{fireImmediately: false}
		);
	}
}
