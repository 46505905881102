import React, {Fragment, PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IEntryPointsController} from "views/components/entry_points/entry_points.controller";
import {PagePreloader} from "views/components/preloader";

export const EntryPoints: React.FC<PropsWithChildren> = observer(({children}) => {
	const {isLoaded} = useViewController<IEntryPointsController>(Bindings.EntryPointsController);

	return isLoaded ? <Fragment>{children}</Fragment> : <PagePreloader />;
});
