import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";

export interface ITooltipContentController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isOpen(): boolean;
	get activeStep(): number;

	toggleOpenState: () => void;
	setActiveStep: (step: number) => void;
}

@injectable()
export class TooltipContentController implements ITooltipContentController {
	get isOpen() {
		return this._tutorialStore.isOpen;
	}

	get activeStep() {
		return this._tutorialStore.activeStep;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TutorialStore) public _tutorialStore: ITutorialStore
	) {
		makeAutoObservable(this);
	}

	toggleOpenState = () => {
		this._tutorialStore.toggleOpenState();
	};

	setActiveStep = (step: number) => {
		this._tutorialStore.setActiveStep(step);
	};
}
