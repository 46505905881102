import {makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {RequestState} from "data/enums";
import type {IPoolPlayersStore} from "data/stores/pool_players/pool_players.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ILeaderboardsStore} from "data/stores/leaderboards/leaderboards.store";
import type {IUserStore} from "data/stores/user/user.store";
import {TrackAnalytics} from "data/utils/analytic_service";

export interface IRosterController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;
}

@injectable()
export class RosterController implements IRosterController {
	@observable _requestState: RequestState = RequestState.IDLE;
	private _disposerReaction!: ReturnType<typeof reaction>;

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore,
		@inject(Bindings.PoolPlayersStore) public _poolPlayersStore: IPoolPlayersStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore,
		@inject(Bindings.LeaderboardsStore) public _leaderboardStore: ILeaderboardsStore
	) {
		makeAutoObservable(this);
	}

	private userAction = (tournamentId: number) => {
		if (this._userStore.isAuthorized && !this._teamStore.isTeamChanged) {
			void this._teamStore.fetchTeam({tournamentId});
		}
		// if (!this._userStore.isAuthorized) {
		// 	this._teamStore.setEditTeam(true);
		// }

		if (!this._tournamentsStore.isSelectedTournamentDisabled) {
			void this._leaderboardStore.fetchPlayersRanking({tournamentId});
		}
	};

	private onLoadPageTrack = () => {
		const payload = {
			event: "Pageload",
			PageName: "roster",
			PageSection: "roster",
			PreviousPageName: "",
			PreviousPageSection: "",
		};

		TrackAnalytics.trackOnLoad(payload);
	};

	dispose() {
		this._disposerReaction?.();
	}

	init(param: void) {
		this._requestState = RequestState.PENDING;
		this.onLoadPageTrack();

		try {
			this._tournamentsStore.setSelectedTournament(
				this._tournamentsStore.currentTournament ?? null
			);

			this._tournamentsStore.setSelectedRoundIndex(this._tournamentsStore.currentRoundIndex);

			this._disposerReaction = reaction(
				() => this._tournamentsStore.selectedTournamentId,
				(tournamentId) => {
					if (tournamentId) {
						void Promise.all([
							this._poolPlayersStore.fetchPoolPlayers({tournamentId}),
							this._tournamentsStore.fetchUsesPlayers({tournamentId}),
							this._leaderboardStore.fetchSidebarRanking(),
						])
							.then(() => {
								this.userAction(tournamentId);
							})
							.then(() => {
								this._requestState = RequestState.SUCCESS;
							});
					}
				},
				{fireImmediately: true}
			);
		} catch (err) {
			this._requestState = RequestState.ERROR;
		}
	}
}
