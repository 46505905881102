export interface ITrackOnLoad {
	event: string;
	PageName: string;
	PageSection: string;
	PreviousPageName?: string;
	PreviousPageSection?: string;
}
export interface ITrackOnAction {
	event: string;
	PageName?: string;
	SelectionName?: string;
	PageArea?: string;
	SelectionTournamentId?: number;
	LeagueId?: number;
}

export abstract class TrackAnalytics {
	public static trackOnLoad(params: ITrackOnLoad) {
		console.log("trackOnLoad---", params);
		try {
			window.dataLayer?.push({params});
		} catch (e) {
			console.log(e);
		}
	}

	public static trackOnAction(params: ITrackOnAction) {
		console.log("trackOnAction---", params);
		try {
			window.dataLayer?.push({params});
		} catch (e) {
			console.log(e);
		}
	}
}
