import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable} from "inversify";

interface IParam {
	divId: string;
	baseDivId: string;
	pos: string;
	ad_test: string | null;
}

export type IAdController = ViewController<IParam>;

@injectable()
export class AdController implements IAdController {
	constructor() {
		makeAutoObservable(this);
	}

	init(param: IParam): void {
		const {divId, baseDivId, pos, ad_test} = param;

		if (window.tude) {
			window.tude.cmd.push(function () {
				window.tude.refreshAdsViaDivMappings([
					{
						divId,
						baseDivId,
						targeting: {
							pos,
							ad_test,
						},
					},
				]);
			});
		}
	}

	dispose(): void {
		if (window.tude) {
			window.tude.cmd.push(function () {
				window.tude.destroyAds();
			});
		}
	}
}
