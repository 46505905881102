import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {useNavigate} from "react-router-dom";
import {eq, includes} from "lodash";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

export interface ITutorialController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get isOpen(): boolean;
	get activeStep(): number;

	toggleOpenState: () => void;
	setActiveStep: (step: number) => void;
	setOpenEditTeam: (value: boolean) => void;
	onNextStep: (value: number) => void;
}

@injectable()
export class TutorialController implements ITutorialController {
	@observable private _navigate?: ReturnType<typeof useNavigate>;
	get isOpen() {
		return this._tutorialStore.isOpen;
	}

	get activeStep() {
		return this._tutorialStore.activeStep;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TutorialStore) public _tutorialStore: ITutorialStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	toggleOpenState = () => {
		this._tutorialStore.toggleOpenState();
	};

	setActiveStep = (step: number) => {
		this._tutorialStore.setActiveStep(step);
	};

	//Deprecated
	setOpenEditTeam = (value: boolean) => {
		this._teamStore.setEditTeam(value);
	};

	onNextStep = (value: number) => {
		if (eq(value, 3)) {
			this._navigate?.("/roster");
		}

		if (eq(value, 5)) {
			// this.setOpenEditTeam(true);
			void this._teamStore.autoFill();
		}

		if (includes([4, 6], value)) {
			this.setOpenEditTeam(false);
			this._teamStore.clearLineup();
		}

		if (eq(value, 7)) {
			this._navigate?.("/leagues");
		}
	};

	@action onChange({navigate}: IProps) {
		this._navigate = navigate;
	}

	@action init(params: IProps) {
		this.onChange(params);
	}

	dispose() {
		return;
	}
}
