import styled from "@emotion/styled";
import React from "react";
import {EntryPoints} from "views/components/entry_points/entry_points.component";
import {Outlet} from "react-router-dom";

export const Main = styled.main`
	display: flex;
	flex-flow: column;
	background: #fff;
	flex: 1;
	align-items: center;
	width: 100%;
	align-self: center;
`;

export const WidgetLayout: React.FC = () => (
	<EntryPoints>
		<Main>
			<Outlet />
		</Main>
	</EntryPoints>
);
