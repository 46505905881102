import {AxiosError, AxiosRequestConfig} from "axios";
import {HttpClientService, type IApiResponse} from "data/services/http/http_client.service";
import {DIContainer} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";

export abstract class HttpClientFactory {
	static createApiClient(config: AxiosRequestConfig) {
		// You can add interceptors here to adjust or modify any of requests/responses values.
		const client = new HttpClientService(config);

		// interceptor to remove user data if session is expired
		client.interceptors.response.use(
			(response) => response,
			(error: AxiosError<IApiResponse>) => {
				const isAuthRequired = error.response?.status === 401;

				if (isAuthRequired) {
					DIContainer.get<IUserStore>(Bindings.UserStore).flushUser();
				}

				return Promise.reject(error);
			}
		);

		return client;
	}

	static createJSONClient(config: AxiosRequestConfig) {
		// You can add interceptors here to adjust or modify any of requests/responses values.
		return new HttpClientService(config);
	}
}
