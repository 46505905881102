import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType, ShareType, SocialNetwork} from "data/enums";
import type {IModalPayload, IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {share} from "data/utils";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {theme} from "assets/theming/theme";

export interface IModalTeamSavedController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get modalContent(): IModalPayload | null;
	get isMobileShareVisible(): boolean;
	get shareTeamImage(): string;

	close: () => void;
	shareViaFB: () => void;
	shareViaTW: () => void;
	shareViaMobile: () => void;
}

@injectable()
export class ModalTeamSavedController implements IModalTeamSavedController {
	@observable private _isNarrowScreen: boolean = false;
	private _disposerReaction!: ReturnType<typeof reaction>;
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TEAM_SAVED;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get shareTeamImage() {
		return this._teamStore.shareTeamImage;
	}

	get isMobileShareVisible() {
		return "share" in navigator && this._isNarrowScreen;
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	private get narrowScreenWatcher() {
		return window.matchMedia(theme.breakpoints.down("md"));
	}

	private share(socialNetwork: SocialNetwork) {
		share({
			teamId: String(this._teamStore.team.id),
			socialNetwork,
			type: ShareType.General,
			lang: this.i18n.lang,
			message: this.i18n.t("team.share.message", "Share team message"),
		});
	}

	shareViaFB = () => this.share(SocialNetwork.Facebook);

	shareViaTW = () => this.share(SocialNetwork.Twitter);

	shareViaMobile = () => this.share(SocialNetwork.Mobile);

	@action private updateNarrowScreenFlag = () => {
		this._isNarrowScreen = this.narrowScreenWatcher.matches;
	};

	init(param: void) {
		this._disposerReaction = reaction(
			() => this.isOpen,
			() => {
				if (this._tournamentsStore.selectedTournamentId) {
					void this._teamStore.generateShareImg({
						tournamentId: this._tournamentsStore.selectedTournamentId,
					});
				}
			},
			{fireImmediately: false}
		);

		this.updateNarrowScreenFlag();
		this.narrowScreenWatcher.addEventListener("change", this.updateNarrowScreenFlag);
	}

	dispose() {
		this._disposerReaction?.();
	}
}
