import {Exist} from "views/components/exist/exist.component";
import {NavLink, useLocation} from "react-router-dom";
import React from "react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {useIsMobile} from "data/hooks";
import {IMenuItems} from "data/types/navigation";
import styled from "@emotion/styled";
import {type IDesktopMenuController} from "views/components/desktop_menu/desktop_menu.controller";
import {PureButton} from "views/components/buttons/buttons.component";
import InfoIcon from "@mui/icons-material/Info";

const Navigation = styled.div`
	background: #ffffff;
	height: 44px;
	width: 100%;
	overflow: hidden;
	//border-bottom: 1px solid #e5e5ea;
	display: flex;
	justify-content: space-between;
`;

const NavigationContent = styled.nav`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: auto;
	justify-content: space-evenly;

	@media (min-width: 768px) {
		justify-content: flex-start;
	}

	li {
		height: 100%;
		padding: 0;
		list-style: none;
		flex: 1;
		@media (min-width: 768px) {
			flex: none;
		}
	}

	a,
	button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 12px 16px;
		color: ${({theme}) => theme.palette.text.medium};
		font-weight: 400;
		font-size: 14px;
		height: 100%;
		border-top: 2px solid transparent;
		border-bottom: 2px solid transparent;
		transition: all 0.2s linear;

		&.active {
			color: #000;
			border-bottom-color: #000;
			font-weight: bold;
		}

		@media (hover: hover) {
			&:hover {
				color: #000000;
				border-bottom-color: #000000;
			}
		}
	}

	.tutorial {
		button {
			gap: 5px;
		}

		svg {
			color: #000000;
		}
	}

	.last {
		margin-left: auto;
	}
`;

interface IDesktopMenu {
	isAuth: boolean;
	menuItems: IMenuItems;
	showTutorial?: () => void;
}

export const DesktopMenu: React.FC<IDesktopMenu> = ({isAuth, menuItems, showTutorial}) => {
	const {i18n} = useViewController<IDesktopMenuController>(Bindings.DesktopMenuController);

	const {isMobile} = useIsMobile();
	const {pathname} = useLocation();

	const links = isAuth ? menuItems.loggedIn.desktop : menuItems.loggedOut.desktop;
	const isAccountOrHelp = pathname.includes("account") || pathname.includes("help");
	const isLeague = pathname.includes("league");
	const isHideComponent = isAccountOrHelp && isMobile;
	if (!links.length || isHideComponent) {
		return null;
	}

	return (
		<Navigation>
			<NavigationContent>
				<Exist when={!isAuth}>
					{menuItems.loggedOut.desktop.map((link) => {
						return (
							<li key={link.key}>
								<NavLink to={link.to} end={link.isEnd?.(pathname)}>
									{i18n.t(link.key, link.value)}
								</NavLink>
							</li>
						);
					})}
				</Exist>
				<Exist when={isAuth}>
					{menuItems.loggedIn.desktop.map((link) => {
						if (isMobile && !link.isMobileVisible) {
							return null;
						}
						const isActiveLeague = link.to.includes("league") && isLeague;

						return (
							<li key={link.key}>
								<NavLink
									className={({isActive}) =>
										isActive || isActiveLeague ? "active" : ""
									}
									to={link.to}
									end={link.isEnd?.(pathname)}>
									{i18n.t(link.key, link.value)}
								</NavLink>
							</li>
						);
					})}

					<Exist when={!isMobile && showTutorial}>
						<li className="ml-auto tutorial">
							<PureButton onClick={showTutorial}>
								{i18n.t("tutorial.button.open", "Tutorial")}
								<InfoIcon />
							</PureButton>
						</li>
					</Exist>
				</Exist>
			</NavigationContent>
		</Navigation>
	);
};
