import {useMediaQuery} from "@mui/material";
import {ORIGIN_FRAME_URL} from "data/constants";

export const useIsMobile = (query?: string) => {
	const isMobile = useMediaQuery(query || "(max-width: 768px)");
	const isMobileShare = "share" in navigator && isMobile;

	return {isMobileShare, isMobile};
};

export const useIsFrameView = () => {
	const query = new URL(ORIGIN_FRAME_URL);
	const params = new URLSearchParams(query.searchParams);

	return Boolean(params.get("isFrame"));
};
