import {createTheme} from "@mui/material/styles";
import {buttonsTheme} from "assets/theming/buttons.theme";
import {typographyTheme} from "assets/theming/typography.theme";
import {paletteTheme} from "assets/theming/palette.theme";
import {componentsThemeOptions} from "assets/theming/components.theme";

export const emptyTheme = createTheme({});
export const theme = createTheme(
	typographyTheme,
	paletteTheme,
	buttonsTheme,
	componentsThemeOptions
);
