import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IPlayersStore, IPlayer} from "data/stores/players/players.store";

interface IProps {
	playerId: number;
}
export interface IPlayerWidgetItemController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get player(): IPlayer | null | undefined;
}

@injectable()
export class PlayerWidgetItemController implements IPlayerWidgetItemController {
	@observable private _playerId!: number;

	get player() {
		if (!this._playerId) {
			return null;
		}
		return this._playersStore.getPlayerById(this._playerId);
	}
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.PlayersStore) public _playersStore: IPlayersStore
	) {
		makeAutoObservable(this);
	}

	init({playerId}: IProps) {
		this._playerId = playerId;
	}
}
