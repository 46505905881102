import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ChangeEvent, FormEvent} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {LeaguePrivacy, ModalType, RequestState} from "data/enums";
import {identity, isEmpty, isEqual, values} from "lodash";
import {extractErrorMessage} from "data/utils";
import {useNavigate} from "react-router-dom";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

interface IForm extends HTMLFormElement {
	leagueName: HTMLInputElement;
	privacy: HTMLInputElement;
}

interface IFormValue {
	leagueName: string;
	// tournamentIds: number[];
	privacy: LeaguePrivacy;
}

export interface ICreateLeagueController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;
	get isCreateButtonDisabled(): boolean;
	get isFormDisabled(): boolean;
	get hasRegenerateLeagues(): boolean;
	get tmpLeague(): ILeague | null;
	get formValue(): IFormValue;

	// handleCreateLeague: () => void;
	handleFormChange: (event: ChangeEvent<IForm>) => void;
	onSubmit: (event: FormEvent<HTMLFormElement>) => void;
	toggleTournamentsModal: () => void;
}

@injectable()
export class CreateLeagueController implements ICreateLeagueController {
	@observable private _roundsDisposer?: ReturnType<typeof reaction>;
	@observable _navigate!: ReturnType<typeof useNavigate>;
	@observable private _requestState = RequestState.PENDING;
	@observable private _tmpLeague: ILeague | null = null;
	@observable private _formValue: IFormValue = {
		leagueName: "",
		// tournamentIds: this._leaguesStore.tournamentsIds,
		privacy: LeaguePrivacy.PRIVATE,
	};

	get formValue() {
		return this._formValue;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isFormDisabled() {
		return Boolean(this.isLoading || this.tmpLeague);
	}

	get isCreateButtonDisabled() {
		return (
			this.isFormDisabled || !this.isFormValid || isEmpty(this._leaguesStore.tournamentsIds)
		);
	}

	get tmpLeague() {
		return this._tmpLeague;
	}

	get hasRegenerateLeagues() {
		return this._leaguesStore.hasRegenerateLeagues;
	}

	@computed private get isFormValid() {
		return values(this._formValue).every(identity);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	toggleTournamentsModal = () => {
		this._leaguesStore.toggleTournamentsModal();
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onSuccessLeagueCreate = (league: ILeague) => {
		this._tmpLeague = league;
		this._leaguesStore.clearTournamentsList();

		if (league.id) {
			this._navigate(`/league/${league.id}/`, {replace: false});
		}

		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action handleFormChange = (event: ChangeEvent<IForm>) => {
		const {leagueName, privacy} = event.currentTarget;

		this._formValue.leagueName = leagueName.value;
		this._formValue.privacy = privacy.value as LeaguePrivacy;
	};

	@action onSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (this.isCreateButtonDisabled) {
			return;
		}

		this._requestState = RequestState.PENDING;

		const {leagueName, privacy} = this._formValue;

		this._leaguesStore
			.createLeague({
				privacy,
				tournamentIds: this._leaguesStore.tournamentsIds,
				name: leagueName,
			})
			.then(this.onSuccessLeagueCreate)
			.catch(this.onError);
	};

	// @action startRoundChange = (event: SelectChangeEvent<unknown>, _: ReactNode) => {
	// 	this._formValue.startId = Number(event.target.value);
	// };

	@action init({navigate}: IProps) {
		this._navigate = navigate;

		this._leaguesStore
			.fetchMyLeagues({isInactiveOnly: 1})
			.then(this.onSuccess)
			.catch(this.onError);

		// this._roundsStore.fetchRounds().then(this.onSuccess).catch(this.onError);
		//
		// this._roundsDisposer = reaction(
		// 	() => this.rounds,
		// 	() => {
		// 		const roundID = first(this.rounds)?.id;
		//
		// 		if (roundID) {
		// 			this._formValue.startId = roundID;
		// 		}
		// 	}
		// );
	}

	dispose() {
		return;
		// this._roundsDisposer?.();
	}
}
