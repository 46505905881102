import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";

export interface ISecretController extends ViewController {
	readonly i18n: ILocalizationStore;

	clickHandler: () => void;
}

@injectable()
export class SecretController implements ISecretController {
	constructor(@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	clickHandler = () => {
		SecretGateController.handleSecretClick();
	};
}
