import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {LeaguePrivacy} from "data/enums";
import type {ILeague} from "data/stores/leagues/leagues.store";
import {GAME_SLUG} from "data/constants";

export interface ICreateLeaguePayload {
	name: string;
	tournamentIds: number[];
	privacy: LeaguePrivacy;
	isActive?: boolean;
}

export interface ILeagueIdPayload {
	leagueId: number;
}

interface IPaginationPayload {
	page?: number;
	limit?: number;
}

export type IUpdateLeaguePayload = Partial<ICreateLeaguePayload> & ILeagueIdPayload;

export interface ILeaguesForJoinPayload extends IPaginationPayload {
	search?: string;
}

export interface ILeagueInvitesPayload extends ILeagueIdPayload {
	invites: {email: string}[];
}

export interface IRemoveUserFromLeaguePayload extends ILeagueIdPayload {
	userId: number;
}

export interface ILeagueCodePayload {
	code: string;
}

export type ILeagueUsersPayload = ILeagueIdPayload & IPaginationPayload;

export interface ILeaguesPayload extends IPaginationPayload {
	privacy?: LeaguePrivacy;
	search?: string;
	limit?: number;
	orderBy?: string;
	isInactiveOnly?: number;
}

export interface ILeagueManager {
	userId: number | null;
	displayName: string;
}

export interface ILeagueUser extends ILeagueManager {
	userId: number;
}

type ILeagueResponse = IApiResponse<{league: ILeague}>;
type ILeaguesListResponse = IApiResponse<{leagues: ILeague[]; nextPage: boolean}>;
type ILeagueUserListResponse = IApiResponse<{users: ILeagueUser[]; nextPage: boolean}>;

export interface ILeaguesApiProvider {
	createLeague: (params: ICreateLeaguePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	updateLeague: (params: IUpdateLeaguePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeague: (params: ILeagueIdPayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeaguesForJoin: (
		params: ILeaguesForJoinPayload
	) => Promise<AxiosResponse<ILeaguesListResponse>>;
	leaveLeague: (params: ILeagueIdPayload) => Promise<AxiosResponse<void>>;
	deleteLeague: (params: ILeagueIdPayload) => Promise<AxiosResponse<void>>;
	inviteUsersToLeague: (params: ILeagueInvitesPayload) => Promise<AxiosResponse<void>>;
	removeUserFromLeague: (params: IRemoveUserFromLeaguePayload) => Promise<AxiosResponse<void>>;
	joinToLeague: (params: ILeagueCodePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeagueUsers: (
		params: ILeagueUsersPayload
	) => Promise<AxiosResponse<ILeagueUserListResponse>>;
	fetchMyLeagues: (params: ILeaguesPayload) => Promise<AxiosResponse<ILeaguesListResponse>>;
	fetchLeagueByCode: (params: ILeagueCodePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	setFavorite: (params: ILeagueIdPayload) => Promise<AxiosResponse>;
	unsetFavorite: (params: ILeagueIdPayload) => Promise<AxiosResponse>;
}

@injectable()
export class LeaguesApiProvider implements ILeaguesApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	createLeague = (params: ICreateLeaguePayload) =>
		this._http.post<ILeagueResponse>(`${GAME_SLUG}/league`, params);

	updateLeague = ({leagueId, ...params}: IUpdateLeaguePayload) =>
		this._http.post<ILeagueResponse>(`${GAME_SLUG}/league/${leagueId}`, params);

	fetchLeague = ({leagueId}: ILeagueIdPayload) =>
		this._http.get<ILeagueResponse>(`${GAME_SLUG}/league/${leagueId}`);

	fetchLeaguesForJoin = (params: ILeaguesForJoinPayload) =>
		this._http.get<ILeaguesListResponse>(`${GAME_SLUG}/league/show-for-join`, params);

	leaveLeague = ({leagueId}: ILeagueIdPayload) =>
		this._http.post<void>(`${GAME_SLUG}/league/${leagueId}/leave`);

	deleteLeague = ({leagueId}: ILeagueIdPayload) =>
		this._http.post<void>(`${GAME_SLUG}/league/${leagueId}/delete`);

	inviteUsersToLeague = ({leagueId, ...params}: ILeagueInvitesPayload) =>
		this._http.post<void>(`${GAME_SLUG}/league/${leagueId}/invite`, params);

	removeUserFromLeague = ({leagueId, userId}: IRemoveUserFromLeaguePayload) =>
		this._http.post<void>(`${GAME_SLUG}/league/${leagueId}/user/${userId}`);

	joinToLeague = ({code}: ILeagueCodePayload) =>
		this._http.post<ILeagueResponse>(`${GAME_SLUG}/league/${code}/join`);

	fetchLeagueUsers = ({leagueId, ...params}: ILeagueUsersPayload) =>
		this._http.get<ILeagueUserListResponse>(
			`${GAME_SLUG}/league/${leagueId}/league-users`,
			params
		);

	fetchMyLeagues = (params: ILeaguesPayload) =>
		this._http.get<ILeaguesListResponse>(`${GAME_SLUG}/leagues`, params);

	fetchLeagueByCode = ({code}: ILeagueCodePayload) =>
		this._http.get<ILeagueResponse>(`${GAME_SLUG}/league/show-by-code/${code}`);

	setFavorite = ({leagueId}: ILeagueIdPayload) =>
		this._http.post<IApiResponse>(
			`${GAME_SLUG}/user-favorite-league/league/${leagueId}/make-favorite`
		);
	unsetFavorite = ({leagueId}: ILeagueIdPayload) =>
		this._http.post<IApiResponse>(
			`${GAME_SLUG}/user-favorite-league/league/${leagueId}/make-not-favorite`
		);
}
