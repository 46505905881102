import {inject, injectable} from "inversify";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse, IHttpClientService} from "data/services/http";

export interface ITeamDataCompareLineupBonuses {
	name: string;
	count: number;
	pts: number;
}

export interface ITeamDataCompareLineup {
	playerId: number;
	isCaptain: boolean;
	feedPosition: string | null;
	tournamentPts: number | null;
	roundPts: number | null;
	bonuses: ITeamDataCompareLineupBonuses[];
}

export interface ITeamDataCompareBench {
	playerId: number;
	isCaptain: boolean;
	feedPosition: string | null;
	tournamentPts: number | null;
	roundPts: number | null;
	bonuses: ITeamDataCompareLineupBonuses[];
}

export interface ITeamDataCompare {
	rank: number | null;
	pts: number | null;
	lineup: ITeamDataCompareLineup[];
	bench: ITeamDataCompareBench[];
}

export interface ITeamCompare {
	roundName: number;
	teamA: ITeamDataCompare;
	teamB: ITeamDataCompare;
}

export interface ITeamPoints {
	tournamentId: number;
	teamAPoints: number | null;
	teamBPoints: number | null;
	teamAName: string;
	teamBName: string;
}

export interface ITeamCompareByTournamentPayload {
	leagueId?: number;
	userId: number;
	tournamentId: number;
}

export interface ITeamComparePayload {
	leagueId: number;
	userId: number;
}

export interface ITeamCompareResponse {
	teamsPoints: ITeamPoints[];
}

export interface ITeamCompareByTournamentResponse {
	teams: ITeamCompare[];
}

export interface ICompareApiProvider {
	compare: (
		params: ITeamComparePayload
	) => Promise<AxiosResponse<IApiResponse<ITeamCompareResponse>>>;
	compareByTournament: (
		params: ITeamCompareByTournamentPayload
	) => Promise<AxiosResponse<IApiResponse<ITeamCompareByTournamentResponse>>>;
}

@injectable()
export class CompareApiProvider implements ICompareApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	compare = (params: ITeamComparePayload) =>
		this._http.get<IApiResponse<ITeamCompareResponse>>(`fantasy/team/compare`, params);

	compareByTournament = (params: ITeamCompareByTournamentPayload) =>
		this._http.get<IApiResponse<ITeamCompareByTournamentResponse>>(
			`fantasy/team/compare-by-tournament`,
			params
		);
}
