import styled from "@emotion/styled";
import {Button, ButtonProps} from "@mui/material";

export const PureButton = styled.button`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
`;

const ButtonPrimaryBase = styled(Button)`
	transition: all ease 0.4s;
`;

ButtonPrimaryBase.defaultProps = {
	variant: "contained",
	sx: {
		borderRadius: "100px",
		background: "#000",
		color: "#FFF",
		fontWeight: "700",
		minHeight: "46px",
		minWidth: "300px",
		textTransform: "none",
		border: "1px solid black",

		"&:disabled": {
			background: "grey",
			border: "1px solid grey",
			color: "#FFF",
		},

		"&:hover": {
			color: "#000",
			background: "#fff",
			border: "1px solid #000",
		},
	},
};

const ButtonPrimaryInvertBase = styled(Button)``;

ButtonPrimaryInvertBase.defaultProps = {
	variant: "contained",
	sx: {
		borderRadius: "100px",
		background: "#FFF",
		color: "#000",
		fontWeight: "700",
		minHeight: "46px",
		minWidth: "300px",
		textTransform: "none",
		border: "1px solid #000",

		"&:hover": {
			background: "#000",
			color: "#FFF",
		},
	},
};

const ButtonSecondaryBase = styled(ButtonPrimaryBase)``;

ButtonSecondaryBase.defaultProps = {
	color: "info",
	sx: {
		background: "#ED0000",
		color: "#FFF",
		borderRadius: "100px",
		border: "1px solid #ED0000",
		fontWeight: "700",
		minHeight: "46px",
		minWidth: "300px",
		textTransform: "none",
		width: "100%",

		"&:hover": {
			background: "#FFF",
			color: "#ED0000",
		},
	},
};

const ButtonSecondaryInvertBase = styled(ButtonPrimaryBase)``;

ButtonSecondaryInvertBase.defaultProps = {
	color: "info",
	sx: {
		background: "#FFF",
		color: "#ED0000",
		borderRadius: "100px",
		fontWeight: "700",
		minHeight: "46px",
		minWidth: "300px",
		textTransform: "none",
		width: "100%",
		border: "1px solid #ED0000",

		"&:hover": {
			background: "#ED0000",
			color: "#FFF",
		},
	},
};

/**
 * Type casting is required because of MUI issue
 * https://github.com/mui/material-ui/issues/13921#issuecomment-484133463
 */
export const ButtonPrimary = ButtonPrimaryBase as typeof Button;
export const ButtonSecondary = ButtonSecondaryBase as typeof Button;
export const ButtonPrimaryInvert = ButtonPrimaryInvertBase as typeof Button;
export const ButtonSecondaryInvert = ButtonSecondaryInvertBase as typeof Button;

export const ButtonPure = styled.button`
	padding: 0;
	margin: 0;
	outline: none;
	background: none;
	border: none;
	cursor: pointer;
`;

export const EditButton = styled(Button)`
	display: flex;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	gap: 4px;

	border-radius: 100px;
	border: 1px solid #949494;

	color: #000;
	text-align: center;
	font-feature-settings:
		"clig" off,
		"liga" off;

	font-family: var(--fontFamilyBase);
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 135%; /* 16.2px */

	text-transform: none;
`;
interface IAddButtonProps extends ButtonProps {
	target?: string;
}

export const AddButton = styled(Button)<IAddButtonProps>`
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	border-radius: 100px;
	border: 1px solid #949494;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;
	left: 50%;
	bottom: -12px;
	transform: translateX(-50%);

	min-width: auto;
	padding: 0;

	&:hover {
		background: var(--primaryColor);

		svg {
			path {
				fill: #fff;
			}
		}
	}
`;
