import React from "react";
import styled from "@emotion/styled";
import {Outlet} from "react-router-dom";
import {EntryPoints} from "views/components/entry_points/entry_points.component";
import {Header} from "views/components/header/header.component";
import {Footer} from "views/components/footer/footer.component";

export const Main = styled.main`
	display: flex;
	flex-flow: column;
	//background: #f6f6f6;
	background: #fff;
	flex: 1;
	align-items: center;
	width: 100%;
	align-self: center;
`;

export const MainLayout: React.FC = () => (
	<EntryPoints>
		<Header />
		<Main>
			<Outlet />
		</Main>
		<Footer />

		{/*<Tutorial />*/}
	</EntryPoints>
);
