import styled from "@emotion/styled";
import {FormControl} from "@mui/material";
import SelectMUI from "@mui/material/Select";
import React from "react";
import {SelectProps} from "@mui/material/Select/Select";
import InputLabel from "@mui/material/InputLabel";

export const SelectFormControl = styled(FormControl)`
	background: transparent;
	border-radius: 4px;
	max-width: 343px;

	.MuiFilledInput-root {
		background: transparent;
	}

	.MuiInputLabel-root,
	.MuiInputLabel-root.Mui-focused {
		color: #a3a3a3;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: var(--primaryColor);
	}

	.MuiFilledInput-root::after {
		border-color: #a3a3a3;
	}

	.MuiSelect-outlined {
		text-align: left;
	}

	.MuiSvgIcon-root {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.87);
	}
`;

SelectFormControl.defaultProps = {
	variant: "outlined",
	fullWidth: true,
};

export const Select: React.FC<SelectProps> = ({
	label,
	defaultValue = "",
	name,
	required,
	children,
	disabled,
	...rest
}) => (
	<SelectFormControl>
		<InputLabel disabled={disabled} required={required}>
			{label}
		</InputLabel>
		<SelectMUI
			defaultValue={defaultValue}
			name={name}
			label={label}
			disabled={disabled}
			required={required}
			{...rest}>
			{children}
		</SelectMUI>
	</SelectFormControl>
);
